import { Block } from 'baseui/block';
import { Button, KIND, SIZE } from 'baseui/button';
import { DatePicker } from 'baseui/datepicker';
import { Delete } from 'baseui/icon';
import { ListItem, ListItemLabel } from 'baseui/list';
import { toaster } from 'baseui/toast';
import { DateTime } from 'luxon';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { CenteredSpinner } from '../../../common/components/CenteredSpinner';
import { PageContent } from '../../../common/components/PageContent';
import {
    getGetCongressCongressidOpenhourListKey,
    postCongressCongressidOpenhourUpdate,
    useGetCongressCongressidOpenhourList,
} from '../../../generated-apis/nZWAppAPI';
import { CongressOpeningHourMessage } from '../../../generated-apis/nZWAppAPI.schemas';

interface OpeningHoursFormState {
    openingHours: CongressOpeningHourMessage[];
}

interface OpeningHoursEditFormProps {
    congressId: number;
    defaultValues: CongressOpeningHourMessage[];
}

const OpeningHoursEditForm = ({ congressId, defaultValues }: OpeningHoursEditFormProps) => {
    const { mutate } = useSWRConfig();
    const { control, handleSubmit, formState, setValue, getValues } = useForm<OpeningHoursFormState>({
        defaultValues: {
            openingHours: defaultValues,
        },
    });

    const handleDelete = (index: number) => {
        setValue(
            'openingHours',
            getValues().openingHours.filter((it, currentIndex) => currentIndex !== index)
        );
    };

    const handleAdd = () => {
        setValue('openingHours', [...getValues().openingHours, { id: 0, opentime: '' }]);
    };

    const onSubmit: SubmitHandler<OpeningHoursFormState> = async (values) => {
        try {
            const response = await postCongressCongressidOpenhourUpdate(congressId, {
                openingHours: values.openingHours.map((it) => ({
                    id: 0,
                    congress: congressId,
                    opentime: DateTime.fromISO(it.opentime).toISO().replace(/\+.+/, 'Z'),
                    closetime: it.closetime ? DateTime.fromISO(it.closetime).toISO().replace(/\+.+/, 'Z') : undefined,
                })),
            });

            await mutate(getGetCongressCongressidOpenhourListKey(congressId), response);

            toaster.positive('Erfolgreich gespeichert');
        } catch (ex) {
            toaster.negative('Speichern fehlgeschlagen');
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Block marginTop="scale800" marginBottom="scale1600">
                <Controller
                    name="openingHours"
                    control={control}
                    render={({ field }) => {
                        return (
                            <div>
                                {field.value.map((openingHour, index) => (
                                    <ListItem
                                        endEnhancer={() => (
                                            <ListItemLabel>
                                                <Button
                                                    size={SIZE.compact}
                                                    kind={KIND.secondary}
                                                    type="button"
                                                    onClick={() => handleDelete(index)}
                                                >
                                                    <Delete />
                                                </Button>
                                            </ListItemLabel>
                                        )}
                                    >
                                        <Block marginRight="scale600" width="100%">
                                            <DatePicker
                                                value={[
                                                    openingHour.opentime ? new Date(openingHour.opentime) : null,
                                                    openingHour.closetime ? new Date(openingHour.closetime) : null,
                                                ]}
                                                onChange={({ date }) => {
                                                    const dateRange = date as Date[];

                                                    const newOpeningHours = field.value.map(
                                                        (currentOpeningHour, currentIndex) => {
                                                            if (index === currentIndex) {
                                                                return {
                                                                    ...currentOpeningHour,
                                                                    opentime: dateRange[0]
                                                                        ? DateTime.fromJSDate(dateRange[0]).toISO()
                                                                        : undefined,
                                                                    closetime: dateRange[1]
                                                                        ? DateTime.fromJSDate(dateRange[1]).toISO()
                                                                        : undefined,
                                                                };
                                                            }

                                                            return currentOpeningHour;
                                                        }
                                                    );

                                                    field.onChange({ target: { value: newOpeningHours } });
                                                }}
                                                range
                                                size={SIZE.compact}
                                                timeSelectStart
                                                timeSelectEnd
                                                formatString="d.MM.yy H:mm"
                                            />
                                        </Block>
                                    </ListItem>
                                ))}
                            </div>
                        );
                    }}
                />

                <Block marginTop="scale600" marginRight="scale600" justifyContent="end" display="flex">
                    <Button size={SIZE.compact} kind={KIND.secondary} type="button" onClick={handleAdd}>
                        Hinzufügen
                    </Button>
                </Block>
            </Block>

            <Button type="submit" isLoading={formState.isSubmitting}>
                Speichern
            </Button>
        </form>
    );
};

export const CongressOpeningHoursPage = () => {
    const { congressId } = useParams();
    const { data } = useGetCongressCongressidOpenhourList(Number(congressId));

    if (!data) {
        return (
            <PageContent title="Öffnungszeiten bearbeiten">
                <CenteredSpinner />
            </PageContent>
        );
    }

    return (
        <PageContent title="Öffnungszeiten bearbeiten">
            <OpeningHoursEditForm defaultValues={data.data} congressId={Number(congressId)} />
        </PageContent>
    );
};
