import { Block } from 'baseui/block';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import React, { useContext } from 'react';
import { useController } from 'react-hook-form';
import { LanguageContext } from '../context/LanguageContextProvider';
import { LanguageSelect } from './LanguageSelect';

interface FormLanguageAwareInputProps {
    name: string;
    label?: string;
}

export const FormLanguageAwareInput = ({ name, label }: FormLanguageAwareInputProps) => {
    const {
        language: { languageId },
    } = useContext(LanguageContext);
    const controller = useController({ name });

    const text = controller.field.value ? controller.field.value[languageId] ?? '' : '';

    return (
        <div>
            <FormControl label={label}>
                <Block display="grid" gridTemplateColumns="auto 1fr" gridColumnGap="scale400">
                    <LanguageSelect />
                    <Input
                        value={text}
                        onChange={(event) => {
                            controller.field.onChange({
                                target: {
                                    value: {
                                        ...controller.field.value,
                                        [languageId]: event.target.value,
                                    },
                                },
                            });
                        }}
                    />
                </Block>
            </FormControl>
        </div>
    );
};
