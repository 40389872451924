import useComponentSize from '@rehooks/component-size';
import { useStyletron } from 'baseui';
import { FixedMarker, KIND } from 'baseui/map-marker';
import { useRef } from 'react';

interface ImageMarker {
    id: number;
    label: string;
    relativeX: number;
    relativeY: number;
    active: boolean;
}

interface RemoteImageProps {
    imageId: number;
    markers?: ImageMarker[];
}

export const RemoteImageWithMarkers = ({ imageId, markers }: RemoteImageProps) => {
    const [css, theme] = useStyletron();
    const imageRef = useRef<HTMLImageElement>(null);
    const { width, height } = useComponentSize(imageRef);

    return (
        <div style={{ position: 'relative' }}>
            <img
                ref={imageRef}
                src={`${process.env.REACT_APP_API_BASE_URL}/image/${imageId}`}
                alt=""
                className={css({
                    ...theme.borders.border200,
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    overflow: 'hidden',
                })}
            />

            {markers && width > 2 && height > 2
                ? markers.map((it) => {
                      const relativePositionLeft = width * it.relativeX;
                      const relativePositionTop = height * it.relativeY;

                      return (
                          <div
                              key={it.id}
                              style={{
                                  position: 'absolute',
                                  top: `${relativePositionTop}px`,
                                  left: `${relativePositionLeft}px`,
                                  transform: 'translate(-50%, -100%)',
                              }}
                          >
                              <FixedMarker kind={it.active ? KIND.accent : KIND.default} label={it.label} />
                          </div>
                      );
                  })
                : null}
        </div>
    );
};
