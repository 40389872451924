import { Block } from 'baseui/block';
import { Button, KIND } from 'baseui/button';
import { FormControl } from 'baseui/form-control';
import { Show } from 'baseui/icon';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { Select } from 'baseui/select';
import React, { useState } from 'react';
import { useController } from 'react-hook-form';
import { RemoteImageWithMarkers } from '../../../common/components/RemoteImageWithMarkers';
import { useGetCongressCongressidRoomList, useGetMapIdGet } from '../../../generated-apis/nZWAppAPI';

interface FormRoomSelectProp {
    congressId: number;
}

export const FormRoomSelect = ({ congressId }: FormRoomSelectProp) => {
    const controller = useController({ name: 'room' });
    const [isOpen, setIsOpen] = useState(false);
    const { data: roomsData } = useGetCongressCongressidRoomList(congressId);
    const room = roomsData?.data.find((it) => it.id === controller.field.value);

    const { data: mapData } = useGetMapIdGet(room?.map!!, {
        swr: {
            enabled: !!room?.map,
        },
    });

    const selectedValue = roomsData?.data.find((it) => it.id === controller.field.value);

    return (
        <div>
            <FormControl label="Raum">
                <Block display="grid" gridTemplateColumns="1fr auto" gridColumnGap="scale400">
                    <Select
                        isLoading={!roomsData}
                        clearable
                        value={selectedValue ? [{ label: selectedValue.name, id: selectedValue.id }] : undefined}
                        onChange={({ value }) => {
                            if (value.length === 0) {
                                controller.field.onChange(undefined);
                            } else {
                                controller.field.onChange(value[0].id);
                            }
                        }}
                        options={
                            roomsData?.data.map((it) => ({
                                label: it.name,
                                id: it.id,
                            })) ?? []
                        }
                    ></Select>
                    {room && mapData ? (
                        <>
                            <Button kind={KIND.secondary} type="button" onClick={() => setIsOpen(true)}>
                                <Show />
                            </Button>
                            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                                <ModalHeader>{mapData.data.label}</ModalHeader>
                                <ModalBody>
                                    <RemoteImageWithMarkers
                                        imageId={mapData.data.image}
                                        markers={[
                                            {
                                                id: room.id,
                                                label: room.name,
                                                active: false,
                                                relativeX: room.coordX,
                                                relativeY: room.coordY,
                                            },
                                        ]}
                                    />
                                </ModalBody>
                            </Modal>
                        </>
                    ) : null}
                </Block>
            </FormControl>
        </div>
    );
};
