import { Block } from 'baseui/block';
import { DatePicker } from 'baseui/datepicker';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { DateTime } from 'luxon';
import React from 'react';
import { useController } from 'react-hook-form';

interface DateTimePickerControllerProps {
    name: string;
    label: string;
}

export const FormDateTimePicker = ({ name, label }: DateTimePickerControllerProps) => {
    const { field } = useController({ name });

    return (
        <div>
            <FormControl label={label}>
                <Block display="flex" gridGap="scale400">
                    <DatePicker
                        value={new Date(field.value)}
                        onChange={({ date }) => field.onChange((date as Date).toISOString())}
                        formatString="d.MM.yy"
                    />
                    <Input
                        value={DateTime.fromISO(field.value).toLocaleString(DateTime.TIME_24_SIMPLE)}
                        placeholder="time"
                        onChange={(event) => {
                            const time = event.target.value;

                            const newDate = DateTime.fromISO(field.value).set({
                                hour: Number(time.split(':')[0]),
                                minute: Number(time.split(':')[1]),
                                second: 0,
                                millisecond: 0,
                            });

                            field.onChange(newDate.toISO());
                        }}
                        type="time"
                        overrides={{
                            Root: { style: { height: '48px' } },
                        }}
                    />
                </Block>
            </FormControl>
        </div>
    );
};
