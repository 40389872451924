import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Block } from 'baseui/block';
import { FormControl } from 'baseui/form-control';
import React, { useContext, useEffect, useRef } from 'react';
import { useController } from 'react-hook-form';
import { LanguageContext } from '../context/LanguageContextProvider';
import { LanguageSelect } from './LanguageSelect';

interface FormLanguageAwareCkEditorProps {
    name: string;
    label?: string;
}

export const FormLanguageAwareCkEditor = ({ name, label }: FormLanguageAwareCkEditorProps) => {
    const {
        language: { languageId },
    } = useContext(LanguageContext);
    const controller = useController({ name });
    const editorRef = useRef<any>();

    const text = controller.field.value ? controller.field.value[languageId] ?? '' : '';

    useEffect(() => {
        const currentEditor = editorRef.current;

        if (currentEditor) {
            currentEditor.data.set(text, { batchType: { isUndoable: true } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languageId]);

    return (
        <div>
            <FormControl label={label}>
                <Block display="grid" gridTemplateColumns="auto 1fr" gridColumnGap="scale400">
                    <div>
                        <LanguageSelect />
                    </div>

                    <CKEditor
                        editor={ClassicEditor}
                        data={text}
                        onReady={(editor: any) => {
                            editorRef.current = editor;
                        }}
                        onChange={(event: unknown, editor: any) => {
                            controller.field.onChange({
                                ...controller.field.value,
                                [languageId]: editor.getData(),
                            });
                        }}
                    />
                </Block>
            </FormControl>
        </div>
    );
};
