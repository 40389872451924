import { Button, SIZE } from 'baseui/button';
import { StyledLink } from 'baseui/link';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { PageContent } from '../../../common/components/PageContent';
import { useGetLocationList } from '../../../generated-apis/nZWAppAPI';
import { LocationMessage } from '../../../generated-apis/nZWAppAPI.schemas';
import { LocationCreateModal } from '../components/LocationCreateModal';

export const LocationListPage = () => {
    const { data } = useGetLocationList();
    const [showCreate, setShowCreate] = useState(false);

    return (
        <PageContent
            title="Veranstaltungsorte"
            actionElement={
                <Button size={SIZE.compact} onClick={() => setShowCreate(true)}>
                    Neu anlegen
                </Button>
            }
        >
            <LocationCreateModal isOpen={showCreate} onClose={() => setShowCreate(false)} />
            <TableBuilder data={data?.data} isLoading={!data} emptyMessage="Keine Veranstaltungsorte">
                <TableBuilderColumn header="Name">
                    {(row: LocationMessage) => (
                        <StyledLink $as={Link} to={`/locations/${row.id}/details`}>
                            {row.name}
                        </StyledLink>
                    )}
                </TableBuilderColumn>
                <TableBuilderColumn header="Stadt">{(row: LocationMessage) => row.city}</TableBuilderColumn>
                <TableBuilderColumn header="Land">{(row: LocationMessage) => row.country}</TableBuilderColumn>
            </TableBuilder>
        </PageContent>
    );
};
