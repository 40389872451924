import { Block } from 'baseui/block';
import { ArrowRight } from 'baseui/icon';
import { StyledLink } from 'baseui/link';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import { DateTime } from 'luxon';
import { Link, useParams } from 'react-router-dom';
import { useGetCongressCongressidEventList, useGetRoomIdGet } from '../../../generated-apis/nZWAppAPI';
import { EventMessage } from '../../../generated-apis/nZWAppAPI.schemas';

interface EventLocationProps {
    roomId: number;
}

const EventLocation = ({ roomId }: EventLocationProps) => {
    const { data: roomData } = useGetRoomIdGet(roomId);

    return <span>{roomData?.data.name}</span>;
};

export const EventsList = () => {
    const { congressId } = useParams();
    const { data: eventsData } = useGetCongressCongressidEventList(Number(congressId));

    return (
        <TableBuilder isLoading={!eventsData} data={eventsData?.data} emptyMessage="Keine Veranstaltungen">
            <TableBuilderColumn header="Name">
                {(row: EventMessage) => (
                    <StyledLink $as={Link} to={`/congresses/${congressId}/events/${row.id}`}>
                        {row.label.find((it) => it.language === 1)?.value || '[Kein Titel]'}
                    </StyledLink>
                )}
            </TableBuilderColumn>
            <TableBuilderColumn header="Typ">{(row: EventMessage) => row.type}</TableBuilderColumn>
            <TableBuilderColumn header="Ort">
                {(row: EventMessage) => (row.room ? <EventLocation roomId={row.room} /> : null)}
            </TableBuilderColumn>
            <TableBuilderColumn header="Datum">
                {(row: EventMessage) => {
                    return (
                        <Block display="flex" alignItems="center">
                            {DateTime.fromISO(row.starttime).toLocaleString(DateTime.DATETIME_SHORT)}
                            <ArrowRight />
                            {row.endtime ? DateTime.fromISO(row.endtime).toLocaleString(DateTime.DATETIME_SHORT) : null}
                        </Block>
                    );
                }}
            </TableBuilderColumn>
        </TableBuilder>
    );
};
