import { toaster } from 'baseui/toast';
import { SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CenteredSpinner } from '../../../common/components/CenteredSpinner';
import { PageContent } from '../../../common/components/PageContent';
import { postReferentIdUpdate, useGetReferentIdGet } from '../../../generated-apis/nZWAppAPI';
import { ReferentMessage } from '../../../generated-apis/nZWAppAPI.schemas';
import { SpeakerEditForm } from '../components/SpeakerEditForm';

export const SpeakerDetailPage = () => {
    const { speakerId } = useParams();
    const { data: referentResponse, mutate } = useGetReferentIdGet(Number(speakerId));

    const onSubmit: SubmitHandler<ReferentMessage> = async (data) => {
        try {
            const response = await postReferentIdUpdate(data.id, data);
            await mutate(response);

            toaster.positive('Erfolgreich gespeichert');
        } catch (error) {
            toaster.negative('Speichern fehlgeschlagen');
        }
    };

    return (
        <PageContent title="Referent bearbeiten">
            {referentResponse?.data ? (
                <SpeakerEditForm defaultValues={referentResponse.data} onSubmit={onSubmit} />
            ) : (
                <CenteredSpinner />
            )}
        </PageContent>
    );
};
