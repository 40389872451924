import { useStyletron } from 'baseui';

interface RemoteImageProps {
    imageId: number;
}

export const RemoteImage = ({ imageId }: RemoteImageProps) => {
    const [css, theme] = useStyletron();
    return (
        <img
            src={`${process.env.REACT_APP_API_BASE_URL}/image/${imageId}`}
            alt=""
            className={css({
                ...theme.borders.border200,
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                overflow: 'hidden',
            })}
        />
    );
};
