import { createTheme } from 'baseui';

const primitives = {
    accent: '#143880',
    accent50: '#e8f1ff',
    accent100: '#c7d9ff',
    accent200: '#96b4ff',
    accent300: '#1a68d5',
    accent400: '#054ba1',
    accent500: '#143880',
    accent600: '#0d3383',
    accent700: '#011f61',
};

export const CustomLightTheme = createTheme(primitives);
