import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { LocationMessage } from '../../../generated-apis/nZWAppAPI.schemas';

interface LocationEditFormProps {
    defaultValues?: LocationMessage;

    onSubmit: SubmitHandler<LocationMessage>;
}

export const LocationEditForm = ({ defaultValues, onSubmit }: LocationEditFormProps) => {
    const [css, theme] = useStyletron();
    const { control, handleSubmit, formState } = useForm<LocationMessage>({
        defaultValues,
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
                name="name"
                control={control}
                render={({ field }) => (
                    <div>
                        <FormControl label="Name">
                            <Input {...field} required />
                        </FormControl>
                    </div>
                )}
            />

            <Controller
                name="street"
                control={control}
                render={({ field }) => (
                    <div>
                        <FormControl label="Straße">
                            <Input {...field} />
                        </FormControl>
                    </div>
                )}
            />
            <Block display="grid" gridTemplateColumns="1fr 3fr" gridColumnGap="16px">
                <Controller
                    name="postcode"
                    control={control}
                    render={({ field }) => (
                        <div>
                            <FormControl label="PLZ">
                                <Input {...field} />
                            </FormControl>
                        </div>
                    )}
                />
                <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                        <div>
                            <FormControl label="Stadt">
                                <Input {...field} />
                            </FormControl>
                        </div>
                    )}
                />
            </Block>
            <Controller
                name="country"
                control={control}
                render={({ field }) => (
                    <div>
                        <FormControl label="Land">
                            <Input {...field} />
                        </FormControl>
                    </div>
                )}
            />

            <Button
                type="submit"
                isLoading={formState.isSubmitting}
                className={css({ marginTop: theme.sizing.scale800 })}
            >
                Speichern
            </Button>
        </form>
    );
};
