import { toaster } from 'baseui/toast';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { PageContent } from '../../../common/components/PageContent';
import { getGetCongressIdGetKey, postCongressCreate } from '../../../generated-apis/nZWAppAPI';
import { CongressMessage } from '../../../generated-apis/nZWAppAPI.schemas';
import { CongressEditForm } from '../components/CongressEditForm';

export const CongressCreatePage = () => {
    const { mutate } = useSWRConfig();
    const navigate = useNavigate();

    const emptyCongress: CongressMessage = {
        enddate: new Date().toISOString(),
        lastChange: new Date().toISOString(),
        location: 0,
        name: '',
        startdate: new Date().toISOString(),
        type: 0,
        id: 0,
    };

    const onSubmit: SubmitHandler<CongressMessage> = async (values) => {
        try {
            const response = await postCongressCreate(values);
            await mutate(getGetCongressIdGetKey(response.data.id), response);

            toaster.positive('Erfolgreich gespeichert');
            navigate(`/congresses/${response.data.id}/details`);
        } catch (ex) {
            toaster.negative('Speichern fehlgeschlagen');
        }
    };

    return (
        <PageContent title="Kongress erstellen">
            <CongressEditForm defaultValues={emptyCongress} onSubmit={onSubmit} />
        </PageContent>
    );
};
