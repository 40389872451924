import { useGetCongresstypeList } from '../../generated-apis/nZWAppAPI';
import { Select } from 'baseui/select';

interface CongressTypeSelectProps {
    value?: number;
    onChange: (event: any) => void;
    onBlur: (event: any) => void;
}

export const CongressTypeSelect = (props: CongressTypeSelectProps) => {
    const { data } = useGetCongresstypeList();

    const options =
        data?.data.map((it) => ({
            label: it.label,
            id: it.id,
        })) ?? [];

    return (
        <Select
            options={options}
            isLoading={!data}
            clearable={false}
            value={options.filter((it) => it.id === props.value)}
            onChange={(params) => {
                props.onChange({ target: { value: params.value[0]?.id } });
            }}
        />
    );
};
