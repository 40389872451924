import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { toaster } from 'baseui/toast';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { PageContent } from '../../../common/components/PageContent';
import { getGetEventIdGetKey, postEventCreate } from '../../../generated-apis/nZWAppAPI';
import { DateTypeMessage } from '../../../generated-apis/nZWAppAPI.schemas';
import { EventEditForm } from '../components/EventEditForm';

interface TranslationObject {
    [languageId: number]: string | number;
}

export interface EventFormState {
    id: number;
    starttime: DateTypeMessage;
    endtime?: DateTypeMessage;
    type: number;
    label: TranslationObject;
    description?: TranslationObject;
    abstractFile?: TranslationObject;
    congress: number;
    room?: number;
}

export const EventCreatePage = () => {
    const { congressId } = useParams();
    const navigate = useNavigate();
    const { mutate: globalMutate } = useSWRConfig();

    const methods = useForm<EventFormState>({
        defaultValues: {
            id: 0,
            type: 1,
            room: undefined,
            congress: Number(congressId),
            starttime: new Date().toISOString(),
            endtime: new Date().toISOString(),
            label: {},
            description: {},
            abstractFile: {},
        },
    });

    const updateEvent: SubmitHandler<EventFormState> = async (values) => {
        try {
            const response = await postEventCreate({
                id: 0,
                congress: values.congress,
                starttime: values.starttime.replace(/\+.+/, 'Z'),
                endtime: values.endtime?.replace(/\+.+/, 'Z'),
                room: values.room,
                type: values.type,
                label: Object.keys(values.label).map((languageId) => ({
                    language: Number(languageId),
                    value: values.label[Number(languageId)].toString(),
                })),
                description: Object.keys(values.description || []).map((languageId) => ({
                    language: Number(languageId),
                    value: values.description?.[Number(languageId)].toString(),
                })),
                abstractFile: Object.keys(values.abstractFile || []).map((languageId) => ({
                    language: Number(languageId),
                    value: values.abstractFile?.[Number(languageId)]
                        ? Number(values.abstractFile?.[Number(languageId)])
                        : undefined,
                })),
            });

            await globalMutate(getGetEventIdGetKey(response.data.id), response);
            toaster.positive('Erfolgreich gespeichert');
            navigate(`/congresses/${congressId}/events/${response.data.id}`);
        } catch (error) {
            toaster.negative('Speichern fehlgeschlagen');
        }
    };

    return (
        <PageContent title="Veranstaltung anlegen">
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(updateEvent)}>
                    <EventEditForm />

                    <Block display="flex" gridColumnGap="8px">
                        <Button isLoading={methods.formState.isSubmitting}>Speichern</Button>
                    </Block>
                </form>
            </FormProvider>
        </PageContent>
    );
};
