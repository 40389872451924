import { Button } from 'baseui/button';
import { toaster } from 'baseui/toast';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { PageContent } from '../../../common/components/PageContent';
import { getGetExhibitorIdGetKey, postExhibitorCreate } from '../../../generated-apis/nZWAppAPI';
import { ExhibitorMessage } from '../../../generated-apis/nZWAppAPI.schemas';
import { ExhibitorEditForm } from '../components/ExhibitorEditForm';

interface CreateExhibitorProps {
    congressId: number;
}

const CreateExhibitor = ({ congressId }: CreateExhibitorProps) => {
    const methods = useForm<ExhibitorMessage>({
        defaultValues: {
            id: 0,
            congress: congressId,
            name: '',
            coordX: 0.5,
            coordY: 0.5,
            logo: 0,
            boothnr: '',
            website: '',
        },
    });

    const { mutate } = useSWRConfig();
    const navigate = useNavigate();

    const createExhibitor: SubmitHandler<ExhibitorMessage> = async (values) => {
        try {
            const response = await postExhibitorCreate(values);
            await mutate(getGetExhibitorIdGetKey(response.data.id), response);

            toaster.positive('Erfolgreich erstellt');
            navigate(`/congresses/${congressId}/exhibitors/${response.data.id}`);
        } catch (error) {
            toaster.negative('Speichern fehlgeschlagen');
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(createExhibitor)}>
                <ExhibitorEditForm control={methods.control} />
                <Button type="submit" isLoading={methods.formState.isSubmitting}>
                    Erstellen
                </Button>
            </form>
        </FormProvider>
    );
};

export const ExhibitorCreatePage = () => {
    const { congressId } = useParams();

    return (
        <PageContent title="Aussteller hinzufügen">
            <CreateExhibitor congressId={Number(congressId)} />
        </PageContent>
    );
};
