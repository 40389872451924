import { Button } from 'baseui/button';
import { toaster } from 'baseui/toast';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { PageContent } from '../../../common/components/PageContent';
import { getGetAdIdGetKey, postAdCreate } from '../../../generated-apis/nZWAppAPI';
import { AdMessage } from '../../../generated-apis/nZWAppAPI.schemas';
import { AdEditForm } from '../components/AdEditForm';

export const AdCreatePage = () => {
    const { congressId } = useParams();
    const { mutate } = useSWRConfig();
    const navigate = useNavigate();

    const { control, handleSubmit, formState } = useForm<AdMessage>({
        defaultValues: {
            id: 0,
            name: '',
            bannerImage: 0,
            relativeShowRate: 1,
            targetUrl: '',
            congress: Number(congressId),
        },
    });

    const createAd: SubmitHandler<AdMessage> = async (values) => {
        try {
            const response = await postAdCreate(values);
            await mutate(getGetAdIdGetKey(response.data.id), response);

            toaster.positive('Erfolgreich erstellt');
            navigate(`/congresses/${congressId}/ads/${response.data.id}`);
        } catch (error) {
            toaster.negative('Speichern fehlgeschlagen');
        }
    };

    return (
        <PageContent title="Werbung hinzufügen">
            <form onSubmit={handleSubmit(createAd)}>
                <AdEditForm control={control} />
                <Button isLoading={formState.isSubmitting}>Erstellen</Button>
            </form>
        </PageContent>
    );
};
