import { styled, useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { HeadingSmall } from 'baseui/typography';
import React, { ReactNode } from 'react';
import { HeaderNavigation } from './HeaderNavigation';

const Layout = styled('div', ({ $theme }) => ({
    margin: `${$theme.sizing.scale600} auto`,
    maxWidth: `calc(1280px + ${$theme.sizing.scale1600} + ${$theme.sizing.scale1600})`,
    paddingLeft: $theme.sizing.scale1600,
    paddingRight: $theme.sizing.scale1600,
}));

interface PageContentProps {
    title: string;
    actionElement?: ReactNode;
    children: ReactNode;
}

export const PageContent = ({ title, actionElement, children }: PageContentProps) => {
    const [, theme] = useStyletron();

    return (
        <>
            <HeaderNavigation />
            <Layout>
                <Block
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    $style={{
                        marginBottom: theme.sizing.scale400,
                        paddingTop: theme.sizing.scale600,
                    }}
                >
                    <HeadingSmall>{title}</HeadingSmall>
                    {actionElement}
                </Block>
                {children}
            </Layout>
        </>
    );
};
