import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { AppNavBar } from 'baseui/app-nav-bar';
import { Block } from 'baseui/block';
import { KIND, Notification } from 'baseui/notification';
import { toaster } from 'baseui/toast';
import { useContext, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { UserAuthenticationContext } from '../../../common/contexts/UserAuthenticationContext';
import { useIsLoggedIn } from '../../../common/hooks/useIsLoggedIn';

const DEFAULT_LOGGED_IN_REDIRECT = '/congresses';

function parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
}

export const LoginPage = () => {
    const navigate = useNavigate();
    const isLoggedIn = useIsLoggedIn();
    const [authenticationError, setAuthenticationError] = useState(false);
    const { setAccessToken } = useContext(UserAuthenticationContext);

    const handleSuccess = (credentialResponse: CredentialResponse) => {
        const emailAddress = parseJwt(credentialResponse.credential!!).email;
        if (emailAddress.endsWith('@conevent.de')) {
            setAccessToken(credentialResponse.credential!!);
            navigate(DEFAULT_LOGGED_IN_REDIRECT);
        } else {
            toaster.negative('Anmeldung fehlgeschlagen');
        }
    };

    const handleError = () => {
        setAuthenticationError(true);
    };

    if (isLoggedIn) {
        return <Navigate to={DEFAULT_LOGGED_IN_REDIRECT} />;
    }

    return (
        <>
            <AppNavBar title="NZW Backend" />
            <Block
                position="absolute"
                top="50%"
                left="50%"
                $style={{
                    transform: 'translate(-50%, -100%)',
                    textAlign: 'center',
                }}
            >
                <GoogleLogin onSuccess={handleSuccess} onError={handleError} />
                {authenticationError ? (
                    <Notification kind={KIND.negative}>Anmeldung fehlgeschlagen</Notification>
                ) : null}
            </Block>
        </>
    );
};
