import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { toaster } from 'baseui/toast';
import { HeadingSmall } from 'baseui/typography';
import { DateTime } from 'luxon';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CenteredSpinner } from '../../../common/components/CenteredSpinner';
import { PageContent } from '../../../common/components/PageContent';
import { postEventIdUpdate, useGetEventIdGet } from '../../../generated-apis/nZWAppAPI';
import { DateTypeMessage, EventMessage } from '../../../generated-apis/nZWAppAPI.schemas';
import { EventEditForm } from '../components/EventEditForm';
import { EventSpeakerEditForm } from '../components/EventSpeakerEditForm';

interface EventUpdateFormProps {
    event: EventMessage;
    mutate: any;
}

interface TranslationObject {
    [languageId: number]: string | number;
}

export interface EventFormState {
    id: number;
    starttime: DateTypeMessage;
    endtime?: DateTypeMessage;
    type: number;
    label: TranslationObject;
    description?: TranslationObject;
    abstractFile?: TranslationObject;
    congress: number;
    room?: number;
}

function initializeTranslationObject(
    acc: TranslationObject,
    translation: { language?: number; value?: string | number }
) {
    acc[translation.language!!] = translation.value || '';

    return acc;
}

const EventUpdateForm = (props: EventUpdateFormProps) => {
    const formMethods = useForm<EventFormState>({
        defaultValues: {
            ...props.event,
            label: props.event.label.reduce(initializeTranslationObject, {}),
            description: props.event.description?.reduce(initializeTranslationObject, {}),
            abstractFile: props.event.abstractFile?.reduce(initializeTranslationObject, {}),
        },
    });

    const updateEvent: SubmitHandler<EventFormState> = async (values) => {
        try {
            await postEventIdUpdate(values.id, {
                id: 0,
                congress: values.congress,
                starttime: values.starttime.replace(/\+.+/, 'Z'),
                endtime: values.endtime?.replace(/\+.+/, 'Z'),
                room: values.room,
                type: values.type,
                label: Object.keys(values.label).map((languageId) => ({
                    language: Number(languageId),
                    value: values.label[Number(languageId)].toString(),
                })),
                description: Object.keys(values.description || []).map((languageId) => ({
                    language: Number(languageId),
                    value: values.description?.[Number(languageId)].toString(),
                })),
                abstractFile: Object.keys(values.abstractFile || []).map((languageId) => ({
                    language: Number(languageId),
                    value: values.abstractFile?.[Number(languageId)]
                        ? Number(values.abstractFile?.[Number(languageId)])
                        : undefined,
                })),
            });

            await props.mutate();

            toaster.positive('Erfolgreich gespeichert');
        } catch (error) {
            toaster.negative('Speichern fehlgeschlagen');
        }
    };

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(updateEvent)}>
                <EventEditForm />

                <Block display="flex" gridColumnGap="8px">
                    <Button isLoading={formMethods.formState.isSubmitting}>Speichern</Button>
                </Block>
            </form>
        </FormProvider>
    );
};

export const EventDetailPage = () => {
    const { eventId } = useParams();
    const { data: eventData, mutate } = useGetEventIdGet(Number(eventId));

    if (!eventData?.data) {
        return (
            <PageContent title="Veranstaltung bearbeiten">
                <CenteredSpinner />
            </PageContent>
        );
    }

    return (
        <PageContent title="Veranstaltung bearbeiten">
            <EventUpdateForm
                event={{
                    ...eventData.data,
                    starttime: DateTime.fromISO(eventData.data.starttime).toISO(),
                    endtime: eventData.data?.endtime
                        ? DateTime.fromISO(eventData.data.endtime).toISO()
                        : new Date().toISOString(),
                }}
                mutate={mutate}
            />
            <Block marginTop="scale1600">
                <HeadingSmall>Referenten</HeadingSmall>
                <EventSpeakerEditForm eventId={Number(eventId)} />
            </Block>
        </PageContent>
    );
};
