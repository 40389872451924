import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { Datepicker } from 'baseui/datepicker';
import { FormControl } from 'baseui/form-control';
import { Select } from 'baseui/select';
import { DateTime } from 'luxon';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FeedMessage } from '../../../generated-apis/nZWAppAPI.schemas';

export interface FeedMessageWithType extends Omit<FeedMessage, 'type'> {
    type: string;
}

interface FeedFormProps {
    defaultValues: FeedMessageWithType;
    onSubmit: SubmitHandler<FeedMessageWithType>;
}

export const FeedEditForm = ({ defaultValues, onSubmit }: FeedFormProps) => {
    const { control, handleSubmit, formState } = useForm<FeedMessageWithType>({
        defaultValues: {
            ...defaultValues,
            date: defaultValues.date ? DateTime.fromISO(defaultValues.date, { zone: 'UTC' }).toISO() : undefined,
        },
    });

    const feedTypeOptions = [
        { label: 'Info', id: 'info' },
        {
            label: 'Änderung',
            id: 'changes',
        },
        { label: 'Absage', id: 'cancel' },
    ];

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Block display="grid" gridTemplateColumns="1fr 1fr" gridColumnGap="scale400">
                <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                        <div>
                            <FormControl label="Typ">
                                <Select
                                    value={
                                        field.value ? feedTypeOptions.filter((it) => it.id === field.value) : undefined
                                    }
                                    options={feedTypeOptions}
                                    searchable={false}
                                    clearable={false}
                                    onChange={({ value }) => field.onChange({ target: { value: value[0].id } })}
                                />
                            </FormControl>
                        </div>
                    )}
                />
                <Controller
                    name="date"
                    control={control}
                    render={({ field }) => (
                        <div>
                            <FormControl label="Datum">
                                <Datepicker
                                    timeSelectStart
                                    formatString="d.MM.yy H:mm"
                                    value={field.value ? new Date(field.value) : undefined}
                                    onChange={({ date, ...rest }) => {
                                        const formattedDate = DateTime.fromJSDate(date as Date).toISO();

                                        field.onChange({
                                            target: { value: formattedDate },
                                        });
                                    }}
                                />
                            </FormControl>
                        </div>
                    )}
                />
            </Block>
            <Controller
                name="text"
                control={control}
                render={({ field }) => (
                    <div>
                        <FormControl label="Text">
                            <CKEditor
                                editor={ClassicEditor}
                                data={field.value}
                                onChange={(event: unknown, editor: any) => {
                                    field.onChange(editor.getData());
                                }}
                            />
                        </FormControl>
                    </div>
                )}
            />

            <Block marginTop="scale800">
                <Button isLoading={formState.isSubmitting}>Speichern</Button>
            </Block>
        </form>
    );
};
