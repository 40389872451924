import { StyledLink } from 'baseui/link';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import { Link, useParams } from 'react-router-dom';
import { useGetCongressCongressidExhibitorList } from '../../../generated-apis/nZWAppAPI';
import { ExhibitorMessage } from '../../../generated-apis/nZWAppAPI.schemas';

export const ExhibitorsList = () => {
    const { congressId } = useParams();
    const { data: exhibitorsData } = useGetCongressCongressidExhibitorList(Number(congressId));

    return (
        <TableBuilder isLoading={!exhibitorsData} data={exhibitorsData?.data} emptyMessage="Keine Aussteller">
            <TableBuilderColumn header="Name">
                {(row: ExhibitorMessage) => (
                    <StyledLink $as={Link} to={`/congresses/${congressId}/exhibitors/${row.id}`}>
                        {row.name}
                    </StyledLink>
                )}
            </TableBuilderColumn>
            <TableBuilderColumn header="Website">{(row: ExhibitorMessage) => row.website}</TableBuilderColumn>
            <TableBuilderColumn header="Standnummer">{(row: ExhibitorMessage) => row.boothnr}</TableBuilderColumn>
        </TableBuilder>
    );
};
