import { GoogleOAuthProvider } from '@react-oauth/google';
import { BaseProvider } from 'baseui';
import { ToasterContainer } from 'baseui/toast';
import React from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';

import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { UserAuthenticationContextProvider } from './common/contexts/UserAuthenticationContext';
import { RootPage } from './common/pages/RootPage';
import { AdCreatePage } from './routes/congress/pages/AdCreatePage';
import { AdDetailPage } from './routes/congress/pages/AdDetailPage';
import { AdListPage } from './routes/congress/pages/AdListPage';
import { CongressCreatePage } from './routes/congress/pages/CongressCreatePage';
import { CongressDetailPage } from './routes/congress/pages/CongressDetailPage';
import { CongressFilesPage } from './routes/congress/pages/CongressFilesPage';
import { CongressListPage } from './routes/congress/pages/CongressListPage';
import { CongressOpeningHoursPage } from './routes/congress/pages/CongressOpeningHoursPage';
import { EventCreatePage } from './routes/congress/pages/EventCreatePage';
import { EventDetailPage } from './routes/congress/pages/EventDetailPage';
import { EventListPage } from './routes/congress/pages/EventListPage';
import { ExhibitorCreatePage } from './routes/congress/pages/ExhibitorCreatePage';
import { ExhibitorDetailPage } from './routes/congress/pages/ExhibitorDetailPage';
import { ExhibitorListPage } from './routes/congress/pages/ExhibitorListPage';
import { FeedCreatePage } from './routes/congress/pages/FeedCreatePage';
import { FeedEditPage } from './routes/congress/pages/FeedEditPage';
import { FeedListPage } from './routes/congress/pages/FeedListPage';
import { LocationDetailPage } from './routes/locations/pages/LocationDetailPage';
import { LocationListPage } from './routes/locations/pages/LocationListPage';
import { LocationMapsPage } from './routes/locations/pages/LocationMapsPage';
import { LoginPage } from './routes/login/pages/LoginPage';
import { LogoutPage } from './routes/logout/pages/LogoutPage';
import { SpeakerDetailPage } from './routes/speakers/pages/SpeakerDetailPage';
import { SpeakerListPage } from './routes/speakers/pages/SpeakerListPage';
import { CustomLightTheme } from './themes/CustomLightTheme';

const engine = new Styletron();

const router = createBrowserRouter([
    {
        path: '/',
        element: <RootPage />,
        children: [
            {
                path: '/congresses',
                element: <CongressListPage />,
            },
            {
                path: '/congresses/create',
                element: <CongressCreatePage />,
            },
            {
                path: '/congresses/:congressId',
                element: <Outlet />,
                children: [
                    {
                        path: '/congresses/:congressId/details',
                        element: <CongressDetailPage />,
                    },
                    {
                        path: '/congresses/:congressId/opening-hours',
                        element: <CongressOpeningHoursPage />,
                    },
                    {
                        path: '/congresses/:congressId/files',
                        element: <CongressFilesPage />,
                    },
                    {
                        path: '/congresses/:congressId/events',
                        element: <EventListPage />,
                    },
                    {
                        path: '/congresses/:congressId/events/create',
                        element: <EventCreatePage />,
                    },
                    {
                        path: '/congresses/:congressId/events/:eventId',
                        element: <EventDetailPage />,
                    },
                    {
                        path: '/congresses/:congressId/exhibitors',
                        element: <ExhibitorListPage />,
                    },
                    {
                        path: '/congresses/:congressId/exhibitors/create',
                        element: <ExhibitorCreatePage />,
                    },
                    {
                        path: '/congresses/:congressId/exhibitors/:exhibitorId',
                        element: <ExhibitorDetailPage />,
                    },
                    {
                        path: '/congresses/:congressId/feeds',
                        element: <FeedListPage />,
                    },
                    {
                        path: '/congresses/:congressId/feeds/create',
                        element: <FeedCreatePage />,
                    },
                    {
                        path: '/congresses/:congressId/feeds/:feedId',
                        element: <FeedEditPage />,
                    },
                    {
                        path: '/congresses/:congressId/ads',
                        element: <AdListPage />,
                    },
                    {
                        path: '/congresses/:congressId/ads/create',
                        element: <AdCreatePage />,
                    },
                    {
                        path: '/congresses/:congressId/ads/:adId',
                        element: <AdDetailPage />,
                    },
                ],
            },
            {
                path: '/locations',
                element: <LocationListPage />,
            },
            {
                path: '/locations/:locationId',
                element: <Outlet />,
                children: [
                    {
                        path: '/locations/:locationId/details',
                        element: <LocationDetailPage />,
                    },
                    {
                        path: '/locations/:locationId/maps',
                        element: <LocationMapsPage />,
                    },
                ],
            },
            {
                path: '/speakers',
                element: <SpeakerListPage />,
            },
            {
                path: '/speakers/:speakerId',
                element: <SpeakerDetailPage />,
            },
            {
                path: '/logout',
                element: <LogoutPage />,
            },
            {
                path: '/login',
                element: <LoginPage />,
            },
        ],
    },
]);

function App() {
    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID!!}>
            <UserAuthenticationContextProvider>
                <StyletronProvider value={engine}>
                    <ToasterContainer autoHideDuration={2_000}>
                        <BaseProvider theme={CustomLightTheme}>
                            <RouterProvider router={router} />
                        </BaseProvider>
                    </ToasterContainer>
                </StyletronProvider>
            </UserAuthenticationContextProvider>
        </GoogleOAuthProvider>
    );
}

export default App;
