import { AppNavBar } from 'baseui/app-nav-bar';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetCongressIdGet } from '../../generated-apis/nZWAppAPI';
import { useUserInfo } from '../hooks/useUserInfo';

export const HeaderNavigation = () => {
    const navigate = useNavigate();
    const { congressId, locationId } = useParams();
    const { pathname } = useLocation();
    const userInfo = useUserInfo();

    const { data } = useGetCongressIdGet(Number(congressId), { swr: { enabled: !!congressId } });

    const mainItems = [
        {
            active: true,
            label: 'Kongresse',
            info: '/congresses',
            children: congressId
                ? [
                      { label: 'Informationen', info: `/congresses/${congressId}/details` },
                      { label: 'Öffnungszeiten', info: `/congresses/${congressId}/opening-hours` },
                      { label: 'Dateien', info: `/congresses/${congressId}/files` },
                      { label: 'Veranstaltungen', info: `/congresses/${congressId}/events` },
                      { label: 'Aussteller', info: `/congresses/${congressId}/exhibitors` },
                      { label: 'Aktuelles', info: `/congresses/${congressId}/feeds` },
                      { label: 'Werbung', info: `/congresses/${congressId}/ads` },
                  ]
                : undefined,
        },
        {
            label: 'Veranstaltungsorte',
            info: '/locations',
            children: locationId
                ? [
                      { label: 'Informationen', info: `/locations/${locationId}/details` },
                      { label: 'Karten', info: `/locations/${locationId}/maps` },
                  ]
                : undefined,
        },
        {
            label: 'Referenten',
            info: '/speakers',
        },
    ].map((it) => ({
        ...it,
        active: pathname.startsWith(it.info),
        children: it.children?.map((child) => ({
            ...child,
            active: pathname.startsWith(child.info),
        })),
    }));

    return (
        <AppNavBar
            title={data?.data?.name ?? 'NZW Backend'}
            mainItems={mainItems}
            onMainItemSelect={(item) => {
                navigate(item.info);
            }}
            username={userInfo?.name}
            userImgUrl={userInfo?.picture}
            userItems={[{ info: '/logout', label: 'Abmelden' }]}
            onUserItemSelect={(item) => {
                navigate(item.info);
            }}
        />
    );
};
