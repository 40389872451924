import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { Datepicker } from 'baseui/datepicker';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { DateTime } from 'luxon';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { CongressTypeSelect } from '../../../common/components/CongressTypeSelect';
import { CongressMessage } from '../../../generated-apis/nZWAppAPI.schemas';
import { LocationSelect } from './LocationSelect';

interface CongressEditFormProps {
    defaultValues: CongressMessage;
    onSubmit: SubmitHandler<CongressMessage>;
}

export const CongressEditForm = ({ defaultValues, onSubmit }: CongressEditFormProps) => {
    const [css, theme] = useStyletron();

    const { control, handleSubmit, formState } = useForm<CongressMessage>({
        defaultValues: {
            ...defaultValues,
            startdate: DateTime.fromISO(defaultValues.startdate, { zone: 'UTC' }).toISO(),
            enddate: DateTime.fromISO(defaultValues.enddate, { zone: 'UTC' }).toISO(),
            lastChange: DateTime.fromISO(defaultValues.lastChange, { zone: 'UTC' }).toISO(),
        },
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Block display="grid" gridTemplateColumns="6fr 2fr" $style={{ columnGap: theme.sizing.scale400 }}>
                <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                        <div>
                            <FormControl label="Name">
                                <Input required {...field} />
                            </FormControl>
                        </div>
                    )}
                />

                <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                        <div>
                            <FormControl label="Typ">
                                <CongressTypeSelect
                                    value={field.value}
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                />
                            </FormControl>
                        </div>
                    )}
                />
            </Block>
            <Block display="grid" gridTemplateColumns="1fr 1fr" $style={{ columnGap: theme.sizing.scale400 }}>
                <Controller
                    name="startdate"
                    control={control}
                    render={({ field }) => {
                        return (
                            <>
                                <div>
                                    <FormControl label="Startdatum">
                                        <Datepicker
                                            value={field.value ? [new Date(field.value)] : undefined}
                                            onChange={({ date }) => {
                                                const formattedDate = DateTime.fromJSDate(date as Date)
                                                    .toISO()
                                                    .replace(/\+.+/, 'Z');

                                                field.onChange({
                                                    target: { value: formattedDate },
                                                });
                                            }}
                                        />
                                    </FormControl>
                                </div>
                            </>
                        );
                    }}
                />
                <Controller
                    name="enddate"
                    control={control}
                    render={({ field }) => (
                        <>
                            <div>
                                <FormControl label="Enddatum">
                                    <Datepicker
                                        value={field.value ? [new Date(field.value)] : undefined}
                                        onChange={({ date }) => {
                                            const formattedDate = DateTime.fromJSDate(date as Date)
                                                .toISO()
                                                .replace(/\+.+/, 'Z');

                                            field.onChange({
                                                target: { value: formattedDate },
                                            });
                                        }}
                                    />
                                </FormControl>
                            </div>
                        </>
                    )}
                />
                <Controller
                    name="location"
                    control={control}
                    render={({ field }) => (
                        <div>
                            <FormControl label="Veranstaltungsort">
                                <LocationSelect {...field} />
                            </FormControl>
                        </div>
                    )}
                />
            </Block>

            <Button
                type="submit"
                isLoading={formState.isSubmitting}
                className={css({ marginTop: theme.sizing.scale800 })}
            >
                Speichern
            </Button>
        </form>
    );
};
