import { Block } from 'baseui/block';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Slider } from 'baseui/slider';
import { Control, Controller } from 'react-hook-form';
import { AdMessage } from '../../../generated-apis/nZWAppAPI.schemas';
import { ImagePreviewAndFileUpload } from '../../locations/components/ImagePreviewAndFileUpload';

interface AdEditFormProps {
    control: Control<AdMessage>;
}

export const AdEditForm = ({ control }: AdEditFormProps) => {
    return (
        <Block display="grid" gridTemplateColumns="1fr auto" gridColumnGap="scale800">
            <div>
                <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                        <div>
                            <FormControl label="Name">
                                <Input {...field} required />
                            </FormControl>
                        </div>
                    )}
                />

                <Controller
                    name="targetUrl"
                    control={control}
                    render={({ field }) => (
                        <div>
                            <FormControl label="Ziel-URL">
                                <Input {...field} />
                            </FormControl>
                        </div>
                    )}
                />

                <Controller
                    name="relativeShowRate"
                    control={control}
                    render={({ field }) => (
                        <div>
                            <FormControl label="Relative Anzeigerate">
                                <Slider
                                    marks
                                    min={0}
                                    max={10}
                                    value={[field.value * 10]}
                                    onChange={(params) => field.onChange({ target: { value: params.value[0] / 10 } })}
                                />
                            </FormControl>
                        </div>
                    )}
                />
            </div>
            <div>
                <Controller
                    name="bannerImage"
                    control={control}
                    render={({ field }) => (
                        <FormControl label="Bild">
                            <Block display="grid" gridTemplateColumns="400px">
                                <ImagePreviewAndFileUpload
                                    onUpload={(uploadedImageId) =>
                                        field.onChange({ target: { value: uploadedImageId } })
                                    }
                                    imageId={field.value}
                                />
                            </Block>
                        </FormControl>
                    )}
                />
            </div>
        </Block>
    );
};
