import { Block } from 'baseui/block';
import { Button, KIND } from 'baseui/button';
import { toaster } from 'baseui/toast';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { CenteredSpinner } from '../../../common/components/CenteredSpinner';
import { PageContent } from '../../../common/components/PageContent';
import {
    deleteAdIdDelete,
    getGetCongressCongressidAdListKey,
    postAdIdUpdate,
    useGetAdIdGet,
} from '../../../generated-apis/nZWAppAPI';
import { AdMessage } from '../../../generated-apis/nZWAppAPI.schemas';
import { AdEditForm } from '../components/AdEditForm';

const AdUpdateForm = (props: { ad: AdMessage; mutate: any }) => {
    const { congressId } = useParams();
    const navigate = useNavigate();
    const [isDeleting, setIsDeleting] = useState(false);
    const { mutate: globalMutate } = useSWRConfig();
    const { control, handleSubmit, formState } = useForm<AdMessage>({
        defaultValues: props.ad,
    });

    const updateAd: SubmitHandler<AdMessage> = async (values) => {
        try {
            await postAdIdUpdate(values.id, values);
            await props.mutate();

            toaster.positive('Erfolgreich gespeichert');
        } catch (error) {
            toaster.negative('Speichern fehlgeschlagen');
        }
    };

    const handleDelete = async () => {
        try {
            setIsDeleting(true);
            await deleteAdIdDelete(props.ad.id);
            await globalMutate(getGetCongressCongressidAdListKey(Number(congressId)));
            toaster.positive('Erfolgreich gelöscht');
            navigate(`/congresses/${congressId}/ads`);
        } catch (ex) {
            setIsDeleting(false);
            toaster.negative('Löschen fehlgeschlagen');
        }
    };

    return (
        <form onSubmit={handleSubmit(updateAd)}>
            <AdEditForm control={control} />

            <Block display="flex" gridColumnGap="8px">
                <Button isLoading={formState.isSubmitting}>Speichern</Button>
                <Button kind={KIND.secondary} type="button" onClick={handleDelete} isLoading={isDeleting}>
                    Löschen
                </Button>
            </Block>
        </form>
    );
};

export const AdDetailPage = () => {
    const { adId } = useParams();
    const { data: adData, mutate } = useGetAdIdGet(Number(adId));

    if (!adData?.data) {
        return <CenteredSpinner />;
    }

    return (
        <PageContent title="Werbung bearbeiten">
            <AdUpdateForm ad={adData.data} mutate={mutate} />
        </PageContent>
    );
};
