/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * NZWApp API
 * API of congress App of NZW.
 * OpenAPI spec version: 2.0.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import useSwr from 'swr';
import type { SWRConfiguration, Key } from 'swr';
import type {
    CongressTypeMessage,
    CongressMessage,
    NotFoundResponse,
    EventMessage,
    FileTypeMessage,
    PostFileUploadRequest,
    CongressOpeningHourMessage,
    UpdateCongressOpeningHourMessage,
    ExhibitorMessage,
    FeedMessage,
    LocationMessage,
    MapTypeMessage,
    RoomMessage,
    ReferentMessage,
    ReferentEventMessage,
    UpdateEventReferentListMessage,
    AdMessage,
    ImageMessage,
    PostImageUploadRequest,
    GetCongressCongressidGetAll200,
    ImportResponseMessage,
    PostCongressCongressidImportRequest,
} from './nZWAppAPI.schemas';

export const getCongresstypeList = (options?: AxiosRequestConfig): Promise<AxiosResponse<CongressTypeMessage[]>> => {
    return axios.get(`/congresstype/list`, options);
};

export const getGetCongresstypeListKey = () => [`/congresstype/list`];

export type GetCongresstypeListQueryResult = NonNullable<Awaited<ReturnType<typeof getCongresstypeList>>>;
export type GetCongresstypeListQueryError = AxiosError<unknown>;

export const useGetCongresstypeList = <TError = AxiosError<unknown>>(options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getCongresstypeList>>, TError> & {
        swrKey?: Key;
        enabled?: boolean;
    };
    axios?: AxiosRequestConfig;
}) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetCongresstypeListKey() : null));
    const swrFn = () => getCongresstypeList(axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const getCongressList = (options?: AxiosRequestConfig): Promise<AxiosResponse<CongressMessage[]>> => {
    return axios.get(`/congress/list`, options);
};

export const getGetCongressListKey = () => [`/congress/list`];

export type GetCongressListQueryResult = NonNullable<Awaited<ReturnType<typeof getCongressList>>>;
export type GetCongressListQueryError = AxiosError<unknown>;

export const useGetCongressList = <TError = AxiosError<unknown>>(options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getCongressList>>, TError> & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
}) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetCongressListKey() : null));
    const swrFn = () => getCongressList(axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const getCongressIdGet = (id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<CongressMessage>> => {
    return axios.get(`/congress/${id}/get`, options);
};

export const getGetCongressIdGetKey = (id: number) => [`/congress/${id}/get`];

export type GetCongressIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getCongressIdGet>>>;
export type GetCongressIdGetQueryError = AxiosError<NotFoundResponse>;

export const useGetCongressIdGet = <TError = AxiosError<NotFoundResponse>>(
    id: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getCongressIdGet>>, TError> & {
            swrKey?: Key;
            enabled?: boolean;
        };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!id;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetCongressIdGetKey(id) : null));
    const swrFn = () => getCongressIdGet(id, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const postCongressCreate = (
    congressMessage: CongressMessage,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<CongressMessage>> => {
    return axios.post(`/congress/create`, congressMessage, options);
};

export const postCongressIdUpdate = (
    id: number,
    congressMessage: CongressMessage,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<CongressMessage>> => {
    return axios.post(`/congress/${id}/update`, congressMessage, options);
};

export const getCongressCongressidEventList = (
    congressid: number,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<EventMessage[]>> => {
    return axios.get(`/congress/${congressid}/event/list`, options);
};

export const getGetCongressCongressidEventListKey = (congressid: number) => [`/congress/${congressid}/event/list`];

export type GetCongressCongressidEventListQueryResult = NonNullable<
    Awaited<ReturnType<typeof getCongressCongressidEventList>>
>;
export type GetCongressCongressidEventListQueryError = AxiosError<unknown>;

export const useGetCongressCongressidEventList = <TError = AxiosError<unknown>>(
    congressid: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getCongressCongressidEventList>>, TError> & {
            swrKey?: Key;
            enabled?: boolean;
        };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!congressid;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetCongressCongressidEventListKey(congressid) : null));
    const swrFn = () => getCongressCongressidEventList(congressid, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const getEventIdGet = (id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<EventMessage>> => {
    return axios.get(`/event/${id}/get`, options);
};

export const getGetEventIdGetKey = (id: number) => [`/event/${id}/get`];

export type GetEventIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getEventIdGet>>>;
export type GetEventIdGetQueryError = AxiosError<NotFoundResponse>;

export const useGetEventIdGet = <TError = AxiosError<NotFoundResponse>>(
    id: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getEventIdGet>>, TError> & { swrKey?: Key; enabled?: boolean };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!id;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetEventIdGetKey(id) : null));
    const swrFn = () => getEventIdGet(id, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const postEventCreate = (
    eventMessage: EventMessage,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<EventMessage>> => {
    return axios.post(`/event/create`, eventMessage, options);
};

export const postEventIdUpdate = (
    id: number,
    eventMessage: EventMessage,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<EventMessage>> => {
    return axios.post(`/event/${id}/update`, eventMessage, options);
};

export const getFileIdGet = (id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<FileTypeMessage>> => {
    return axios.get(`/file/${id}/get`, options);
};

export const getGetFileIdGetKey = (id: number) => [`/file/${id}/get`];

export type GetFileIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getFileIdGet>>>;
export type GetFileIdGetQueryError = AxiosError<NotFoundResponse>;

export const useGetFileIdGet = <TError = AxiosError<NotFoundResponse>>(
    id: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getFileIdGet>>, TError> & { swrKey?: Key; enabled?: boolean };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!id;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetFileIdGetKey(id) : null));
    const swrFn = () => getFileIdGet(id, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const postFileUpload = (
    postFileUploadRequest: PostFileUploadRequest,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<FileTypeMessage>> => {
    const formData = new FormData();
    if (postFileUploadRequest.congress !== undefined) {
        formData.append('congress', postFileUploadRequest.congress.toString());
    }
    if (postFileUploadRequest.filename !== undefined) {
        formData.append('filename', postFileUploadRequest.filename);
    }
    if (postFileUploadRequest.fileext !== undefined) {
        formData.append('fileext', postFileUploadRequest.fileext);
    }
    if (postFileUploadRequest.file !== undefined) {
        formData.append('file', postFileUploadRequest.file);
    }

    return axios.post(`/file/upload`, formData, options);
};

export const getFileIdDownload = (id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Blob>> => {
    return axios.get(`/file/${id}/download`, {
        responseType: 'blob',
        ...options,
    });
};

export const getGetFileIdDownloadKey = (id: number) => [`/file/${id}/download`];

export type GetFileIdDownloadQueryResult = NonNullable<Awaited<ReturnType<typeof getFileIdDownload>>>;
export type GetFileIdDownloadQueryError = AxiosError<NotFoundResponse>;

export const useGetFileIdDownload = <TError = AxiosError<NotFoundResponse>>(
    id: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getFileIdDownload>>, TError> & {
            swrKey?: Key;
            enabled?: boolean;
        };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!id;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetFileIdDownloadKey(id) : null));
    const swrFn = () => getFileIdDownload(id, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const deleteFileIdDelete = (id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> => {
    return axios.delete(`/file/${id}/delete`, options);
};

export const getCongressCongressidFileList = (
    congressid: number,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<FileTypeMessage[]>> => {
    return axios.get(`/congress/${congressid}/file/list`, options);
};

export const getGetCongressCongressidFileListKey = (congressid: number) => [`/congress/${congressid}/file/list`];

export type GetCongressCongressidFileListQueryResult = NonNullable<
    Awaited<ReturnType<typeof getCongressCongressidFileList>>
>;
export type GetCongressCongressidFileListQueryError = AxiosError<unknown>;

export const useGetCongressCongressidFileList = <TError = AxiosError<unknown>>(
    congressid: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getCongressCongressidFileList>>, TError> & {
            swrKey?: Key;
            enabled?: boolean;
        };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!congressid;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetCongressCongressidFileListKey(congressid) : null));
    const swrFn = () => getCongressCongressidFileList(congressid, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const getCongressCongressidOpenhourList = (
    congressid: number,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<CongressOpeningHourMessage[]>> => {
    return axios.get(`/congress/${congressid}/openhour/list`, options);
};

export const getGetCongressCongressidOpenhourListKey = (congressid: number) => [
    `/congress/${congressid}/openhour/list`,
];

export type GetCongressCongressidOpenhourListQueryResult = NonNullable<
    Awaited<ReturnType<typeof getCongressCongressidOpenhourList>>
>;
export type GetCongressCongressidOpenhourListQueryError = AxiosError<unknown>;

export const useGetCongressCongressidOpenhourList = <TError = AxiosError<unknown>>(
    congressid: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getCongressCongressidOpenhourList>>, TError> & {
            swrKey?: Key;
            enabled?: boolean;
        };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!congressid;
    const swrKey =
        swrOptions?.swrKey ?? (() => (isEnabled ? getGetCongressCongressidOpenhourListKey(congressid) : null));
    const swrFn = () => getCongressCongressidOpenhourList(congressid, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const postCongressCongressidOpenhourUpdate = (
    congressid: number,
    updateCongressOpeningHourMessage: UpdateCongressOpeningHourMessage,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<CongressOpeningHourMessage[]>> => {
    return axios.post(`/congress/${congressid}/openhour/update`, updateCongressOpeningHourMessage, options);
};

export const getCongressCongressidExhibitorList = (
    congressid: number,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<ExhibitorMessage[]>> => {
    return axios.get(`/congress/${congressid}/exhibitor/list`, options);
};

export const getGetCongressCongressidExhibitorListKey = (congressid: number) => [
    `/congress/${congressid}/exhibitor/list`,
];

export type GetCongressCongressidExhibitorListQueryResult = NonNullable<
    Awaited<ReturnType<typeof getCongressCongressidExhibitorList>>
>;
export type GetCongressCongressidExhibitorListQueryError = AxiosError<unknown>;

export const useGetCongressCongressidExhibitorList = <TError = AxiosError<unknown>>(
    congressid: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getCongressCongressidExhibitorList>>, TError> & {
            swrKey?: Key;
            enabled?: boolean;
        };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!congressid;
    const swrKey =
        swrOptions?.swrKey ?? (() => (isEnabled ? getGetCongressCongressidExhibitorListKey(congressid) : null));
    const swrFn = () => getCongressCongressidExhibitorList(congressid, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const getExhibitorIdGet = (
    id: number,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<ExhibitorMessage>> => {
    return axios.get(`/exhibitor/${id}/get`, options);
};

export const getGetExhibitorIdGetKey = (id: number) => [`/exhibitor/${id}/get`];

export type GetExhibitorIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getExhibitorIdGet>>>;
export type GetExhibitorIdGetQueryError = AxiosError<NotFoundResponse>;

export const useGetExhibitorIdGet = <TError = AxiosError<NotFoundResponse>>(
    id: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getExhibitorIdGet>>, TError> & {
            swrKey?: Key;
            enabled?: boolean;
        };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!id;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetExhibitorIdGetKey(id) : null));
    const swrFn = () => getExhibitorIdGet(id, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const postExhibitorCreate = (
    exhibitorMessage: ExhibitorMessage,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<ExhibitorMessage>> => {
    return axios.post(`/exhibitor/create`, exhibitorMessage, options);
};

export const postExhibitorIdUpdate = (
    id: number,
    exhibitorMessage: ExhibitorMessage,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<ExhibitorMessage>> => {
    return axios.post(`/exhibitor/${id}/update`, exhibitorMessage, options);
};

export const deleteExhibitorIdDelete = (id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> => {
    return axios.delete(`/exhibitor/${id}/delete`, options);
};

export const getCongressCongressidFeedList = (
    congressid: number,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<FeedMessage[]>> => {
    return axios.get(`/congress/${congressid}/feed/list`, options);
};

export const getGetCongressCongressidFeedListKey = (congressid: number) => [`/congress/${congressid}/feed/list`];

export type GetCongressCongressidFeedListQueryResult = NonNullable<
    Awaited<ReturnType<typeof getCongressCongressidFeedList>>
>;
export type GetCongressCongressidFeedListQueryError = AxiosError<unknown>;

export const useGetCongressCongressidFeedList = <TError = AxiosError<unknown>>(
    congressid: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getCongressCongressidFeedList>>, TError> & {
            swrKey?: Key;
            enabled?: boolean;
        };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!congressid;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetCongressCongressidFeedListKey(congressid) : null));
    const swrFn = () => getCongressCongressidFeedList(congressid, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const getFeedIdGet = (id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<FeedMessage>> => {
    return axios.get(`/feed/${id}/get`, options);
};

export const getGetFeedIdGetKey = (id: number) => [`/feed/${id}/get`];

export type GetFeedIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getFeedIdGet>>>;
export type GetFeedIdGetQueryError = AxiosError<NotFoundResponse>;

export const useGetFeedIdGet = <TError = AxiosError<NotFoundResponse>>(
    id: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getFeedIdGet>>, TError> & { swrKey?: Key; enabled?: boolean };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!id;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetFeedIdGetKey(id) : null));
    const swrFn = () => getFeedIdGet(id, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const postFeedCreate = (
    feedMessage: FeedMessage,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<FeedMessage>> => {
    return axios.post(`/feed/create`, feedMessage, options);
};

export const postFeedIdUpdate = (
    id: number,
    feedMessage: FeedMessage,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<FeedMessage>> => {
    return axios.post(`/feed/${id}/update`, feedMessage, options);
};

export const getLocationList = (options?: AxiosRequestConfig): Promise<AxiosResponse<LocationMessage[]>> => {
    return axios.get(`/location/list`, options);
};

export const getGetLocationListKey = () => [`/location/list`];

export type GetLocationListQueryResult = NonNullable<Awaited<ReturnType<typeof getLocationList>>>;
export type GetLocationListQueryError = AxiosError<unknown>;

export const useGetLocationList = <TError = AxiosError<unknown>>(options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getLocationList>>, TError> & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
}) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetLocationListKey() : null));
    const swrFn = () => getLocationList(axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const getLocationIdGet = (id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<LocationMessage>> => {
    return axios.get(`/location/${id}/get`, options);
};

export const getGetLocationIdGetKey = (id: number) => [`/location/${id}/get`];

export type GetLocationIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getLocationIdGet>>>;
export type GetLocationIdGetQueryError = AxiosError<NotFoundResponse>;

export const useGetLocationIdGet = <TError = AxiosError<NotFoundResponse>>(
    id: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getLocationIdGet>>, TError> & {
            swrKey?: Key;
            enabled?: boolean;
        };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!id;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetLocationIdGetKey(id) : null));
    const swrFn = () => getLocationIdGet(id, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const postLocationCreate = (
    locationMessage: LocationMessage,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<LocationMessage>> => {
    return axios.post(`/location/create`, locationMessage, options);
};

export const postLocationIdUpdate = (
    id: number,
    locationMessage: LocationMessage,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<LocationMessage>> => {
    return axios.post(`/location/${id}/update`, locationMessage, options);
};

export const getMapIdGet = (id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<MapTypeMessage>> => {
    return axios.get(`/map/${id}/get`, options);
};

export const getGetMapIdGetKey = (id: number) => [`/map/${id}/get`];

export type GetMapIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getMapIdGet>>>;
export type GetMapIdGetQueryError = AxiosError<NotFoundResponse>;

export const useGetMapIdGet = <TError = AxiosError<NotFoundResponse>>(
    id: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getMapIdGet>>, TError> & { swrKey?: Key; enabled?: boolean };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!id;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetMapIdGetKey(id) : null));
    const swrFn = () => getMapIdGet(id, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const postMapCreate = (
    mapTypeMessage: MapTypeMessage,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<MapTypeMessage>> => {
    return axios.post(`/map/create`, mapTypeMessage, options);
};

export const postMapIdUpdate = (
    id: number,
    mapTypeMessage: MapTypeMessage,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<MapTypeMessage>> => {
    return axios.post(`/map/${id}/update`, mapTypeMessage, options);
};

export const deleteMapIdDelete = (id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> => {
    return axios.delete(`/map/${id}/delete`, options);
};

export const getLocationLocationidMapList = (
    locationid: number,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<MapTypeMessage[]>> => {
    return axios.get(`/location/${locationid}/map/list`, options);
};

export const getGetLocationLocationidMapListKey = (locationid: number) => [`/location/${locationid}/map/list`];

export type GetLocationLocationidMapListQueryResult = NonNullable<
    Awaited<ReturnType<typeof getLocationLocationidMapList>>
>;
export type GetLocationLocationidMapListQueryError = AxiosError<unknown>;

export const useGetLocationLocationidMapList = <TError = AxiosError<unknown>>(
    locationid: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getLocationLocationidMapList>>, TError> & {
            swrKey?: Key;
            enabled?: boolean;
        };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!locationid;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetLocationLocationidMapListKey(locationid) : null));
    const swrFn = () => getLocationLocationidMapList(locationid, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const getRoomIdGet = (id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<RoomMessage>> => {
    return axios.get(`/room/${id}/get`, options);
};

export const getGetRoomIdGetKey = (id: number) => [`/room/${id}/get`];

export type GetRoomIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRoomIdGet>>>;
export type GetRoomIdGetQueryError = AxiosError<NotFoundResponse>;

export const useGetRoomIdGet = <TError = AxiosError<NotFoundResponse>>(
    id: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getRoomIdGet>>, TError> & { swrKey?: Key; enabled?: boolean };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!id;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetRoomIdGetKey(id) : null));
    const swrFn = () => getRoomIdGet(id, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const postRoomCreate = (
    roomMessage: RoomMessage,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<RoomMessage>> => {
    return axios.post(`/room/create`, roomMessage, options);
};

export const postRoomIdUpdate = (
    id: number,
    roomMessage: RoomMessage,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<RoomMessage>> => {
    return axios.post(`/room/${id}/update`, roomMessage, options);
};

export const deleteRoomIdDelete = (id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> => {
    return axios.delete(`/room/${id}/delete`, options);
};

export const getMapMapidRoomList = (
    mapid: number,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<RoomMessage[]>> => {
    return axios.get(`/map/${mapid}/room/list`, options);
};

export const getGetMapMapidRoomListKey = (mapid: number) => [`/map/${mapid}/room/list`];

export type GetMapMapidRoomListQueryResult = NonNullable<Awaited<ReturnType<typeof getMapMapidRoomList>>>;
export type GetMapMapidRoomListQueryError = AxiosError<unknown>;

export const useGetMapMapidRoomList = <TError = AxiosError<unknown>>(
    mapid: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getMapMapidRoomList>>, TError> & {
            swrKey?: Key;
            enabled?: boolean;
        };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!mapid;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetMapMapidRoomListKey(mapid) : null));
    const swrFn = () => getMapMapidRoomList(mapid, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const getReferentIdGet = (id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<ReferentMessage>> => {
    return axios.get(`/referent/${id}/get`, options);
};

export const getGetReferentIdGetKey = (id: number) => [`/referent/${id}/get`];

export type GetReferentIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getReferentIdGet>>>;
export type GetReferentIdGetQueryError = AxiosError<NotFoundResponse>;

export const useGetReferentIdGet = <TError = AxiosError<NotFoundResponse>>(
    id: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getReferentIdGet>>, TError> & {
            swrKey?: Key;
            enabled?: boolean;
        };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!id;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetReferentIdGetKey(id) : null));
    const swrFn = () => getReferentIdGet(id, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const getReferentList = (options?: AxiosRequestConfig): Promise<AxiosResponse<ReferentMessage[]>> => {
    return axios.get(`/referent/list`, options);
};

export const getGetReferentListKey = () => [`/referent/list`];

export type GetReferentListQueryResult = NonNullable<Awaited<ReturnType<typeof getReferentList>>>;
export type GetReferentListQueryError = AxiosError<unknown>;

export const useGetReferentList = <TError = AxiosError<unknown>>(options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getReferentList>>, TError> & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
}) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetReferentListKey() : null));
    const swrFn = () => getReferentList(axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const postReferentCreate = (
    referentMessage: ReferentMessage,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<ReferentMessage>> => {
    return axios.post(`/referent/create`, referentMessage, options);
};

export const postReferentIdUpdate = (
    id: number,
    referentMessage: ReferentMessage,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<ReferentMessage>> => {
    return axios.post(`/referent/${id}/update`, referentMessage, options);
};

export const getEventEventidReferentList = (
    eventid: number,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<ReferentEventMessage[]>> => {
    return axios.get(`/event/${eventid}/referent/list`, options);
};

export const getGetEventEventidReferentListKey = (eventid: number) => [`/event/${eventid}/referent/list`];

export type GetEventEventidReferentListQueryResult = NonNullable<
    Awaited<ReturnType<typeof getEventEventidReferentList>>
>;
export type GetEventEventidReferentListQueryError = AxiosError<unknown>;

export const useGetEventEventidReferentList = <TError = AxiosError<unknown>>(
    eventid: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getEventEventidReferentList>>, TError> & {
            swrKey?: Key;
            enabled?: boolean;
        };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!eventid;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetEventEventidReferentListKey(eventid) : null));
    const swrFn = () => getEventEventidReferentList(eventid, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const postEventEventidReferentUpdate = (
    eventid: number,
    updateEventReferentListMessage: UpdateEventReferentListMessage,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<ReferentEventMessage[]>> => {
    return axios.post(`/event/${eventid}/referent/update`, updateEventReferentListMessage, options);
};

export const getAdIdGet = (id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<AdMessage>> => {
    return axios.get(`/ad/${id}/get`, options);
};

export const getGetAdIdGetKey = (id: number) => [`/ad/${id}/get`];

export type GetAdIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getAdIdGet>>>;
export type GetAdIdGetQueryError = AxiosError<NotFoundResponse>;

export const useGetAdIdGet = <TError = AxiosError<NotFoundResponse>>(
    id: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getAdIdGet>>, TError> & { swrKey?: Key; enabled?: boolean };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!id;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetAdIdGetKey(id) : null));
    const swrFn = () => getAdIdGet(id, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const postAdCreate = (adMessage: AdMessage, options?: AxiosRequestConfig): Promise<AxiosResponse<AdMessage>> => {
    return axios.post(`/ad/create`, adMessage, options);
};

export const postAdIdUpdate = (
    id: number,
    adMessage: AdMessage,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<AdMessage>> => {
    return axios.post(`/ad/${id}/update`, adMessage, options);
};

export const deleteAdIdDelete = (id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> => {
    return axios.delete(`/ad/${id}/delete`, options);
};

export const getCongressCongressidAdList = (
    congressid: number,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<AdMessage[]>> => {
    return axios.get(`/congress/${congressid}/ad/list`, options);
};

export const getGetCongressCongressidAdListKey = (congressid: number) => [`/congress/${congressid}/ad/list`];

export type GetCongressCongressidAdListQueryResult = NonNullable<
    Awaited<ReturnType<typeof getCongressCongressidAdList>>
>;
export type GetCongressCongressidAdListQueryError = AxiosError<unknown>;

export const useGetCongressCongressidAdList = <TError = AxiosError<unknown>>(
    congressid: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getCongressCongressidAdList>>, TError> & {
            swrKey?: Key;
            enabled?: boolean;
        };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!congressid;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetCongressCongressidAdListKey(congressid) : null));
    const swrFn = () => getCongressCongressidAdList(congressid, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const getCongressCongressidRoomList = (
    congressid: number,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<RoomMessage[]>> => {
    return axios.get(`/congress/${congressid}/room/list`, options);
};

export const getGetCongressCongressidRoomListKey = (congressid: number) => [`/congress/${congressid}/room/list`];

export type GetCongressCongressidRoomListQueryResult = NonNullable<
    Awaited<ReturnType<typeof getCongressCongressidRoomList>>
>;
export type GetCongressCongressidRoomListQueryError = AxiosError<unknown>;

export const useGetCongressCongressidRoomList = <TError = AxiosError<unknown>>(
    congressid: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getCongressCongressidRoomList>>, TError> & {
            swrKey?: Key;
            enabled?: boolean;
        };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!congressid;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetCongressCongressidRoomListKey(congressid) : null));
    const swrFn = () => getCongressCongressidRoomList(congressid, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const getImageId = (id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Blob>> => {
    return axios.get(`/image/${id}`, {
        responseType: 'blob',
        ...options,
    });
};

export const getGetImageIdKey = (id: number) => [`/image/${id}`];

export type GetImageIdQueryResult = NonNullable<Awaited<ReturnType<typeof getImageId>>>;
export type GetImageIdQueryError = AxiosError<NotFoundResponse>;

export const useGetImageId = <TError = AxiosError<NotFoundResponse>>(
    id: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getImageId>>, TError> & { swrKey?: Key; enabled?: boolean };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!id;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetImageIdKey(id) : null));
    const swrFn = () => getImageId(id, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const postImageUpload = (
    postImageUploadRequest: PostImageUploadRequest,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<ImageMessage>> => {
    const formData = new FormData();
    if (postImageUploadRequest.name !== undefined) {
        formData.append('name', postImageUploadRequest.name);
    }
    if (postImageUploadRequest.file !== undefined) {
        formData.append('file', postImageUploadRequest.file);
    }

    return axios.post(`/image/upload`, formData, options);
};

export const getCongressCongressidGetAll = (
    congressid: number,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<GetCongressCongressidGetAll200>> => {
    return axios.get(`/congress/${congressid}/get/all`, options);
};

export const getGetCongressCongressidGetAllKey = (congressid: number) => [`/congress/${congressid}/get/all`];

export type GetCongressCongressidGetAllQueryResult = NonNullable<
    Awaited<ReturnType<typeof getCongressCongressidGetAll>>
>;
export type GetCongressCongressidGetAllQueryError = AxiosError<NotFoundResponse>;

export const useGetCongressCongressidGetAll = <TError = AxiosError<NotFoundResponse>>(
    congressid: number,
    options?: {
        swr?: SWRConfiguration<Awaited<ReturnType<typeof getCongressCongressidGetAll>>, TError> & {
            swrKey?: Key;
            enabled?: boolean;
        };
        axios?: AxiosRequestConfig;
    }
) => {
    const { swr: swrOptions, axios: axiosOptions } = options ?? {};

    const isEnabled = swrOptions?.enabled !== false && !!congressid;
    const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getGetCongressCongressidGetAllKey(congressid) : null));
    const swrFn = () => getCongressCongressidGetAll(congressid, axiosOptions);

    const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

    return {
        swrKey,
        ...query,
    };
};

export const postCongressCongressidImport = (
    congressid: number,
    postCongressCongressidImportRequest: PostCongressCongressidImportRequest,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<ImportResponseMessage>> => {
    return axios.post(`/congress/${congressid}/import`, postCongressCongressidImportRequest, options);
};

export const postCongressCongressidImportSubmit = (
    congressid: number,
    importResponseMessage: ImportResponseMessage,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<ImportResponseMessage>> => {
    return axios.post(`/congress/${congressid}/import/submit`, importResponseMessage, options);
};
