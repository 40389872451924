import { Select } from 'baseui/select';
import { ForwardedRef, forwardRef } from 'react';
import { useGetLocationList } from '../../../generated-apis/nZWAppAPI';

interface LocationSelectProps {
    value: number;
    onChange: (event: any) => void;
}

export const LocationSelect = forwardRef(
    ({ value, onChange }: LocationSelectProps, ref: ForwardedRef<HTMLInputElement>) => {
        const { data: locationData } = useGetLocationList();
        const currentValue = locationData?.data.filter((it) => it.id === value);

        return (
            <Select
                inputRef={ref}
                isLoading={!locationData}
                value={currentValue}
                onChange={({ value }) => {
                    onChange({ target: { value: value[0].id } });
                }}
                clearable={false}
                options={locationData?.data.map((it) => ({
                    id: it.id,
                    label: it.name,
                }))}
            />
        );
    }
);
