import { Select } from 'baseui/select';
import React from 'react';
import { useController } from 'react-hook-form';

interface FormEventTypeSelectProps {
    name: string;
}

export const FormEventTypeSelect = ({ name }: FormEventTypeSelectProps) => {
    const { field } = useController({ name });
    const eventTypes = [
        {
            id: 1,
            label: 'lecture',
        },
        {
            id: 2,
            label: 'ptacongress',
        },
        {
            id: 3,
            label: 'workshop',
        },
        {
            id: 4,
            label: 'break',
        },
        {
            id: 5,
            label: 'oralia',
        },
        {
            id: 6,
            label: 'symposien',
        },
        {
            id: 7,
            label: 'certificate',
        },
        {
            id: 8,
            label: 'esop',
        },
        {
            id: 9,
            label: 'nzwkolleg',
        },
    ];

    return (
        <Select
            options={eventTypes}
            value={eventTypes.filter((it) => it.id === field.value)}
            clearable={false}
            onChange={({ value }) =>
                field.onChange({
                    target: {
                        value: value[0].id,
                    },
                })
            }
        />
    );
};
