import { DropFilesEventHandler, FileUploader } from 'baseui/file-uploader';
import { useState } from 'react';
import { RemoteImage } from '../../../common/components/RemoteImage';
import { postImageUpload } from '../../../generated-apis/nZWAppAPI';

interface ImagePreviewAndFileUploadProps {
    imageId?: number;
    onUpload: (uploadedImageId: number) => void;
}

export const ImagePreviewAndFileUpload = ({ imageId, onUpload }: ImagePreviewAndFileUploadProps) => {
    const [imageUploadProgress, setImageUploadProgress] = useState<number | undefined>(undefined);
    const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState<string>();

    const handleImageUpload: DropFilesEventHandler = async (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            setFileUploadErrorMessage('Datei hat das falsche Format oder ist zu groß');
            return;
        }

        try {
            const response = await postImageUpload(
                {
                    file: acceptedFiles[0],
                    name: acceptedFiles[0].name,
                },
                {
                    onUploadProgress: (progressEvent) => {
                        const currentProgress = progressEvent.upload ? undefined : progressEvent.progress!! * 100;
                        setImageUploadProgress(currentProgress);
                    },
                }
            );

            onUpload(response.data.id!!);
        } catch (ex) {
            setFileUploadErrorMessage('Fehler beim upload');
        }
    };

    return (
        <>
            {imageId ? (
                <div style={{ height: '300px', marginBottom: '16px' }}>
                    <RemoteImage imageId={imageId} />
                </div>
            ) : null}
            <FileUploader
                multiple={false}
                accept="image/*"
                errorMessage={fileUploadErrorMessage}
                maxSize={2_097_152}
                progressAmount={imageUploadProgress}
                onDrop={handleImageUpload}
                onRetry={() => {
                    setFileUploadErrorMessage(undefined);
                }}
            />
        </>
    );
};
