import { Modal, ModalBody, ModalHeader, ModalProps } from 'baseui/modal';
import { toaster } from 'baseui/toast';
import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { getGetLocationIdGetKey, postLocationCreate } from '../../../generated-apis/nZWAppAPI';
import { LocationMessage } from '../../../generated-apis/nZWAppAPI.schemas';
import { LocationEditForm } from './LocationEditForm';

interface LocationCreateModalProps extends Pick<ModalProps, 'isOpen' | 'onClose'> {}

export const LocationCreateModal = ({ isOpen, onClose }: LocationCreateModalProps) => {
    const navigate = useNavigate();
    const { mutate } = useSWRConfig();
    const onSubmit: SubmitHandler<LocationMessage> = async (data) => {
        try {
            const response = await postLocationCreate(data);
            await mutate(getGetLocationIdGetKey(response.data.id), response);

            toaster.positive('Erfolgreich gespeichert');
            navigate(`/locations/${response.data.id}/details`);
        } catch (error) {
            toaster.negative('Speichern fehlgeschlagen');
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} overrides={{ Dialog: { style: { width: '50vw' } } }}>
            <ModalHeader>Veranstaltungsort hinzufügen</ModalHeader>
            <ModalBody>
                <LocationEditForm
                    onSubmit={onSubmit}
                    defaultValues={{
                        id: 0,
                        name: '',
                        street: '',
                        postcode: '',
                        city: '',
                        country: '',
                    }}
                />
            </ModalBody>
        </Modal>
    );
};
