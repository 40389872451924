import { useStyletron } from 'baseui';
import { Banner, KIND } from 'baseui/banner';
import { Button, SIZE } from 'baseui/button';
import { ArrowRight } from 'baseui/icon';
import { StyledLink } from 'baseui/link';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { PageContent } from '../../../common/components/PageContent';
import { useGetCongressList, useGetCongresstypeList, useGetLocationList } from '../../../generated-apis/nZWAppAPI';
import { CongressMessage, CongressTypeMessage, LocationMessage } from '../../../generated-apis/nZWAppAPI.schemas';

interface CombinedCongressInformation extends Omit<CongressMessage, 'location' | 'type'> {
    location: LocationMessage;
    type: CongressTypeMessage;
}

export const CongressListPage = () => {
    const { data: congressData, error } = useGetCongressList();
    const { data: locationData } = useGetLocationList();
    const { data: congressTypeData } = useGetCongresstypeList();

    const [css] = useStyletron();

    if (error) {
        return (
            <PageContent title="Kongresse">
                <Banner title="Fehler" kind={KIND.negative}>
                    Daten konnten nicht geladen werden.
                </Banner>
            </PageContent>
        );
    }
    const combinedCongressData = congressData?.data
        .map((congress) => {
            return {
                ...congress,
                location: locationData?.data.find((it) => it.id === congress.location),
                type: congressTypeData?.data.find((it) => it.id === congress.type),
            };
        })
        .sort((a, b) => Date.parse(b.startdate) - Date.parse(a.startdate));

    return (
        <PageContent
            title="Kongresse"
            actionElement={
                <Button size={SIZE.compact} $as={Link} to={`/congresses/create`}>
                    Neu anlegen
                </Button>
            }
        >
            <TableBuilder
                data={combinedCongressData}
                isLoading={!congressData || !locationData || !congressTypeData}
                emptyMessage="Keine Kongresse"
            >
                <TableBuilderColumn header="Name">
                    {(row: CombinedCongressInformation) => (
                        <StyledLink $as={Link} to={`/congresses/${row.id}/details`}>
                            {row.name}
                        </StyledLink>
                    )}
                </TableBuilderColumn>
                <TableBuilderColumn header="Typ">
                    {(row: CombinedCongressInformation) => (
                        <span className={css({ color: row.type.color })}>{row.type.label}</span>
                    )}
                </TableBuilderColumn>
                <TableBuilderColumn header="Datum">
                    {(row: CombinedCongressInformation) => {
                        return (
                            <div className={css({ display: 'flex', alignItems: 'center' })}>
                                {DateTime.fromISO(row.startdate).toLocaleString(DateTime.DATE_MED)} <ArrowRight />
                                {DateTime.fromISO(row.enddate).toLocaleString(DateTime.DATE_MED)}
                            </div>
                        );
                    }}
                </TableBuilderColumn>
                <TableBuilderColumn header="Location">
                    {(row: CombinedCongressInformation) => row.location.name}
                </TableBuilderColumn>
            </TableBuilder>
        </PageContent>
    );
};
