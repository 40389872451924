import { useParams } from 'react-router-dom';
import { CenteredSpinner } from '../../../common/components/CenteredSpinner';
import { PageContent } from '../../../common/components/PageContent';
import { useGetLocationIdGet } from '../../../generated-apis/nZWAppAPI';
import { MapsEditForm } from '../components/MapsEditForm';

export const LocationMapsPage = () => {
    const { locationId } = useParams();
    const { data } = useGetLocationIdGet(Number(locationId));

    if (!data) {
        return (
            <PageContent title="Karten bearbeiten">
                <CenteredSpinner />
            </PageContent>
        );
    }

    return (
        <PageContent title={`Karten für ${data.data.name} bearbeiten`}>
            <MapsEditForm />
        </PageContent>
    );
};
