import { toaster } from 'baseui/toast';
import { SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CenteredSpinner } from '../../../common/components/CenteredSpinner';
import { PageContent } from '../../../common/components/PageContent';
import { postLocationIdUpdate, useGetLocationIdGet } from '../../../generated-apis/nZWAppAPI';
import { LocationMessage } from '../../../generated-apis/nZWAppAPI.schemas';
import { LocationEditForm } from '../components/LocationEditForm';

export const LocationDetailPage = () => {
    const { locationId } = useParams();
    const { data, mutate } = useGetLocationIdGet(Number(locationId));

    const handleSubmit: SubmitHandler<LocationMessage> = async (data) => {
        try {
            const response = await postLocationIdUpdate(data.id, data);
            await mutate(response);

            toaster.positive('Erfolgreich gespeichert');
        } catch (error) {
            toaster.negative('Speichern fehlgeschlagen');
        }
    };

    if (!data) {
        return (
            <PageContent title="Veranstaltungsort bearbeiten">
                <CenteredSpinner />
            </PageContent>
        );
    }

    return (
        <PageContent title="Veranstaltungsort bearbeiten">
            <LocationEditForm defaultValues={data?.data} onSubmit={handleSubmit} />
        </PageContent>
    );
};
