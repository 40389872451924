import { toaster } from 'baseui/toast';
import { SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CenteredSpinner } from '../../../common/components/CenteredSpinner';
import { PageContent } from '../../../common/components/PageContent';
import { postFeedIdUpdate, useGetFeedIdGet } from '../../../generated-apis/nZWAppAPI';
import { FeedEditForm, FeedMessageWithType } from '../components/FeedEditForm';

export const FeedEditPage = () => {
    const { feedId } = useParams();

    const { data: feedData, mutate } = useGetFeedIdGet(Number(feedId));

    const updateFeed: SubmitHandler<FeedMessageWithType> = async (values) => {
        try {
            await postFeedIdUpdate(values.id, {
                ...values,
                // the server does not accept non-Z timezones and also considers Z as the local zone
                // therefore we disregard the timezone from the iso string and replace it with Z
                date: values.date.replace(/\+.+/, 'Z'),
            });
            await mutate();

            toaster.positive('Erfolgreich gespeichert');
        } catch (error) {
            toaster.negative('Speichern fehlgeschlagen');
        }
    };

    if (!feedData) {
        return (
            <PageContent title="Nachricht bearbeiten">
                <CenteredSpinner />
            </PageContent>
        );
    }

    return (
        <PageContent title="Nachricht bearbeiten">
            <FeedEditForm onSubmit={updateFeed} defaultValues={feedData.data as FeedMessageWithType} />
        </PageContent>
    );
};
