import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal';
import { toaster } from 'baseui/toast';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useSWRConfig } from 'swr';
import { deleteMapIdDelete, getGetLocationLocationidMapListKey } from '../../../generated-apis/nZWAppAPI';
import { MapTypeMessage } from '../../../generated-apis/nZWAppAPI.schemas';
import { ImagePreviewAndFileUpload } from './ImagePreviewAndFileUpload';

interface MapDetailEditModalProps {
    locationId: number;
    map: MapTypeMessage;
    onClose: () => void;
    onSubmit: SubmitHandler<MapTypeMessage>;
}

export const MapDetailEditModal = ({ locationId, map, onClose, onSubmit }: MapDetailEditModalProps) => {
    const { mutate } = useSWRConfig();
    const { control, handleSubmit, formState } = useForm<MapTypeMessage>({
        defaultValues: map,
    });

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const handleDelete = async () => {
        if (isDeleting) {
            return;
        }

        setIsDeleting(true);
        try {
            await deleteMapIdDelete(map.id);
            await mutate(getGetLocationLocationidMapListKey(locationId));
            toaster.positive('Erfolgreich gelöscht');
            onClose();
        } catch (ex) {
            toaster.negative('Löschen fehlgeschlagen');
            setIsDeleting(false);
        }
    };

    return (
        <Modal onClose={onClose} size="default" isOpen overrides={{ Dialog: { style: { width: '600px' } } }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader>Karte {map.id > 0 ? 'bearbeiten' : 'hinzufügen'}</ModalHeader>
                <ModalBody>
                    <Controller
                        name="label"
                        control={control}
                        render={({ field }) => (
                            <div style={{ marginBottom: '16px' }}>
                                <FormControl label="Name">
                                    <Input {...field} required />
                                </FormControl>
                            </div>
                        )}
                    />

                    <Controller
                        name="image"
                        control={control}
                        render={({ field }) => (
                            <ImagePreviewAndFileUpload
                                imageId={field.value}
                                onUpload={(newImageId) => {
                                    field.onChange({ target: { value: newImageId } });
                                }}
                            />
                        )}
                    />
                </ModalBody>
                <ModalFooter>
                    {map.id > 0 ? (
                        <ModalButton
                            kind="secondary"
                            disabled={formState.isSubmitting}
                            type="button"
                            onClick={() => setShowDeleteModal(true)}
                        >
                            Löschen
                        </ModalButton>
                    ) : null}
                    <ModalButton type="submit" isLoading={formState.isSubmitting}>
                        Speichern
                    </ModalButton>
                </ModalFooter>
                <Modal isOpen={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
                    <ModalHeader>Karte wirklich löschen?</ModalHeader>
                    <ModalBody>Dir Karte {map.label} wird unwiderruflich entfernt.</ModalBody>
                    <ModalFooter>
                        <ModalButton
                            kind="secondary"
                            disabled={isDeleting}
                            onClick={() => {
                                if (!isDeleting) {
                                    setShowDeleteModal(false);
                                }
                            }}
                        >
                            Abbrechen
                        </ModalButton>
                        <ModalButton isLoading={isDeleting} onClick={handleDelete}>
                            Löschen
                        </ModalButton>
                    </ModalFooter>
                </Modal>
            </form>
        </Modal>
    );
};
