import { Block } from 'baseui/block';
import { FormControl } from 'baseui/form-control';
import React from 'react';
import { useParams } from 'react-router-dom';
import { LanguageContextProvider } from '../context/LanguageContextProvider';
import { FormDateTimePicker } from './FormDateTimePicker';
import { FormEventTypeSelect } from './FormEventTypeSelect';
import { FormLanguageAwareCkEditor } from './FormLanguageAwareCkEditor';
import { FormLanguageAwareFileUpload } from './FormLanguageAwareFileUpload';
import { FormLanguageAwareInput } from './FormLanguageAwareInput';
import { FormRoomSelect } from './FormRoomSelect';

export const EventEditForm = () => {
    const { congressId } = useParams();

    return (
        <LanguageContextProvider>
            <FormControl label="Typ">
                <FormEventTypeSelect name="type" />
            </FormControl>
            <FormLanguageAwareInput name="label" label="Titel" />
            <FormLanguageAwareCkEditor name="description" label="Beschreibung" />

            <FormLanguageAwareFileUpload name="abstractFile" label="Abstract" />

            <Block display="grid" gridTemplateColumns="1fr 1fr" gridColumnGap="scale600">
                <FormDateTimePicker name="starttime" label="Startzeit" />
                <FormDateTimePicker name="endtime" label="Endzeit" />
            </Block>
            <FormRoomSelect congressId={Number(congressId)} />
        </LanguageContextProvider>
    );
};
