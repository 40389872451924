import { AxiosResponse } from 'axios';
import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { toaster } from 'baseui/toast';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { KeyedMutator, useSWRConfig } from 'swr';
import { CenteredSpinner } from '../../../common/components/CenteredSpinner';
import { PageContent } from '../../../common/components/PageContent';
import {
    deleteExhibitorIdDelete,
    getGetCongressCongressidExhibitorListKey,
    postExhibitorIdUpdate,
    useGetExhibitorIdGet,
} from '../../../generated-apis/nZWAppAPI';
import { ExhibitorMessage } from '../../../generated-apis/nZWAppAPI.schemas';
import { ExhibitorEditForm } from '../components/ExhibitorEditForm';

interface EditExhibitorProps {
    exhibitor: ExhibitorMessage;
    mutate: KeyedMutator<AxiosResponse<ExhibitorMessage, any>>;
}

const EditExhibitor = ({ exhibitor, mutate }: EditExhibitorProps) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const navigate = useNavigate();
    const { mutate: globalMutate } = useSWRConfig();
    const methods = useForm<ExhibitorMessage>({
        defaultValues: exhibitor,
    });

    const updateExhibitor: SubmitHandler<ExhibitorMessage> = async (values) => {
        try {
            const response = await postExhibitorIdUpdate(values.id, values);
            await mutate(response);

            toaster.positive('Erfolgreich gespeichert');
        } catch (error) {
            toaster.negative('Speichern fehlgeschlagen');
        }
    };

    const deleteExhibitor = async () => {
        try {
            setIsDeleting(true);
            await deleteExhibitorIdDelete(exhibitor.id);
            await globalMutate(getGetCongressCongressidExhibitorListKey(exhibitor.congress));
            toaster.positive('Erfolgreich gelöscht');
            navigate(`/congresses/${exhibitor.congress}/exhibitors`);
        } catch (ex) {
            setIsDeleting(false);
            toaster.negative('Löschen fehlgeschlagen');
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(updateExhibitor)}>
                <ExhibitorEditForm control={methods.control} />
                <Block display="flex" gridColumnGap="scale400">
                    <Button type="submit" isLoading={methods.formState.isSubmitting}>
                        Speichern
                    </Button>
                    <Button type="button" kind="secondary" isLoading={isDeleting} onClick={deleteExhibitor}>
                        Löschen
                    </Button>
                </Block>
            </form>
        </FormProvider>
    );
};

export const ExhibitorDetailPage = () => {
    const { exhibitorId } = useParams();
    const { data: exhibitor, mutate } = useGetExhibitorIdGet(Number(exhibitorId));

    if (!exhibitor) {
        return <CenteredSpinner />;
    }

    return (
        <PageContent title="Aussteller bearbeiten">
            <EditExhibitor exhibitor={exhibitor.data} mutate={mutate} />
        </PageContent>
    );
};
