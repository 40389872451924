import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ReferentMessage } from '../../../generated-apis/nZWAppAPI.schemas';

interface SpeakerEditFormProps {
    defaultValues?: ReferentMessage;
    onSubmit: SubmitHandler<ReferentMessage>;
}

export const SpeakerEditForm = ({ defaultValues, onSubmit }: SpeakerEditFormProps) => {
    const { control, handleSubmit, formState } = useForm<ReferentMessage>({
        defaultValues: defaultValues,
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Block display="grid" gridTemplateColumns="1fr 3fr 3fr" $style={{ columnGap: '16px' }}>
                <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                        <div>
                            <FormControl label="Titel">
                                <Input {...field} />
                            </FormControl>
                        </div>
                    )}
                />
                <Controller
                    name="firstname"
                    control={control}
                    render={({ field }) => (
                        <div>
                            <FormControl label="Vorname">
                                <Input {...field} required />
                            </FormControl>
                        </div>
                    )}
                />
                <Controller
                    name="lastname"
                    control={control}
                    render={({ field }) => (
                        <div>
                            <FormControl label="Nachname">
                                <Input {...field} />
                            </FormControl>
                        </div>
                    )}
                />
            </Block>
            <Block display="grid" gridTemplateColumns="1fr 1fr" $style={{ columnGap: '16px' }}>
                <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                        <div>
                            <FormControl label="Stadt">
                                <Input {...field} />
                            </FormControl>
                        </div>
                    )}
                />
                <Controller
                    name="country"
                    control={control}
                    render={({ field }) => (
                        <div>
                            <FormControl label="Land">
                                <Input {...field} />
                            </FormControl>
                        </div>
                    )}
                />
            </Block>
            <Block marginTop="scale800">
                <Button type="submit" isLoading={formState.isSubmitting}>
                    Speichern
                </Button>
            </Block>
        </form>
    );
};
