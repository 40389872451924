import { Button, SIZE } from 'baseui/button';
import { StyledLink } from 'baseui/link';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { PageContent } from '../../../common/components/PageContent';
import { useGetReferentList } from '../../../generated-apis/nZWAppAPI';
import { ReferentMessage } from '../../../generated-apis/nZWAppAPI.schemas';
import { SpeakerCreateModal } from '../components/SpeakerCreateModal';

export const SpeakerListPage = () => {
    const { data } = useGetReferentList();
    const [showCreate, setShowCreate] = useState(false);

    return (
        <PageContent
            title="Referenten"
            actionElement={
                <Button size={SIZE.compact} onClick={() => setShowCreate(true)}>
                    Neu anlegen
                </Button>
            }
        >
            <SpeakerCreateModal isOpen={showCreate} onClose={() => setShowCreate(false)} />
            <TableBuilder data={data?.data} isLoading={!data}>
                <TableBuilderColumn header="Name">
                    {(row: ReferentMessage) => (
                        <StyledLink $as={Link} to={`/speakers/${row.id}`}>
                            {row.title} {row.firstname} {row.lastname}
                        </StyledLink>
                    )}
                </TableBuilderColumn>
                <TableBuilderColumn header="Ort">
                    {(row: ReferentMessage) => [row.city, row.country].filter((it) => !!it).join(', ')}
                </TableBuilderColumn>
            </TableBuilder>
        </PageContent>
    );
};
