import { Block } from 'baseui/block';
import { toaster } from 'baseui/toast';
import { ParagraphSmall } from 'baseui/typography';
import { DateTime } from 'luxon';
import { SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CenteredSpinner } from '../../../common/components/CenteredSpinner';
import { PageContent } from '../../../common/components/PageContent';
import { postCongressIdUpdate, useGetCongressIdGet } from '../../../generated-apis/nZWAppAPI';
import { CongressMessage } from '../../../generated-apis/nZWAppAPI.schemas';
import { CongressEditForm } from '../components/CongressEditForm';

export const CongressDetailPage = () => {
    const { congressId } = useParams();
    const { data, mutate } = useGetCongressIdGet(Number(congressId));

    if (!data) {
        return (
            <PageContent title="Kongress">
                <CenteredSpinner />
            </PageContent>
        );
    }

    const onSubmit: SubmitHandler<CongressMessage> = async (values) => {
        try {
            const response = await postCongressIdUpdate(values.id, values);
            await mutate(response);

            toaster.positive('Erfolgreich gespeichert');
        } catch (ex) {
            toaster.negative('Speichern fehlgeschlagen');
        }
    };

    return (
        <PageContent title="Kongress bearbeiten">
            <ParagraphSmall>Letztes Update: {DateTime.fromISO(data?.data.lastChange).toLocaleString()}</ParagraphSmall>
            <Block marginTop="scale800">
                <CongressEditForm defaultValues={data?.data} onSubmit={onSubmit} />
            </Block>
        </PageContent>
    );
};
