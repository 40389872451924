import { useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { UserAuthenticationContext } from '../../../common/contexts/UserAuthenticationContext';

export const LogoutPage = () => {
    const navigate = useNavigate();
    const { setAccessToken } = useContext(UserAuthenticationContext);

    useEffect(() => {
        setAccessToken(null);
        navigate('/');
    }, [navigate, setAccessToken]);

    return null;
};
