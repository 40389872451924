import { Block } from 'baseui/block';
import { Button, KIND, SIZE } from 'baseui/button';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import { toaster } from 'baseui/toast';
import * as React from 'react';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { RemoteImageWithMarkers } from '../../../common/components/RemoteImageWithMarkers';
import {
    deleteRoomIdDelete,
    postRoomCreate,
    postRoomIdUpdate,
    useGetMapMapidRoomList,
} from '../../../generated-apis/nZWAppAPI';
import { MapTypeMessage, RoomMessage } from '../../../generated-apis/nZWAppAPI.schemas';

interface EditRoomModalProps {
    room: RoomMessage;
    onClose: () => void;
    onSubmit: SubmitHandler<RoomMessage>;
}

export const EditRoomModal = (props: EditRoomModalProps) => {
    const { control, handleSubmit, formState } = useForm<RoomMessage>({
        defaultValues: props.room,
    });

    return (
        <Modal isOpen onClose={props.onClose}>
            <form onSubmit={handleSubmit(props.onSubmit)}>
                <ModalHeader>Raum {props.room.id ? 'bearbeiten' : 'hinzufügen'}</ModalHeader>
                <ModalBody>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                            <div>
                                <FormControl label="Name">
                                    <Input {...field} required />
                                </FormControl>
                            </div>
                        )}
                    />
                    <Block marginTop="scale400" display="grid" gridTemplateColumns="1fr 1fr" gridColumnGap="scale400">
                        <Controller
                            name="coordX"
                            control={control}
                            render={({ field }) => (
                                <div>
                                    <FormControl label="X Position">
                                        <Input {...field} required />
                                    </FormControl>
                                </div>
                            )}
                        />
                        <Controller
                            name="coordY"
                            control={control}
                            render={({ field }) => (
                                <div>
                                    <FormControl label="Y Position">
                                        <Input {...field} required />
                                    </FormControl>
                                </div>
                            )}
                        />
                    </Block>
                </ModalBody>
                <ModalFooter>
                    <ModalButton onClick={props.onClose} kind={KIND.tertiary}>
                        Abbrechen
                    </ModalButton>
                    <ModalButton isLoading={formState.isSubmitting}>Speichern</ModalButton>
                </ModalFooter>
            </form>
        </Modal>
    );
};

interface MapRoomsEditModalProps {
    map: MapTypeMessage;

    onClose: () => void;
}

export const MapRoomsEditModal = (props: MapRoomsEditModalProps) => {
    const { data: roomsData, mutate } = useGetMapMapidRoomList(props.map.id);
    const [activeRoom, setActiveRoom] = useState<RoomMessage | undefined>();

    const handleUpdateRoom = async (values: RoomMessage) => {
        try {
            if (values.id) {
                await postRoomIdUpdate(values.id, values);
            } else {
                await postRoomCreate(values);
            }

            await mutate();

            toaster.positive('Erfolgreich gespeichert');
            setActiveRoom(undefined);
        } catch (error) {
            toaster.negative('Speichern fehlgeschlagen');
        }
    };

    const handleDelete = async (roomId: number) => {
        // eslint-disable-next-line
        if (confirm('Wirklich löschen?')) {
            // todo: create a modal with better ux
            try {
                await deleteRoomIdDelete(roomId);
                await mutate();
                toaster.positive('Erfolgreich gelöscht');
            } catch (ex) {
                toaster.negative('Löschen fehlgeschlagen');
            }
        }
    };

    return (
        <Modal isOpen onClose={props.onClose} overrides={{ Dialog: { style: { width: '70vw' } } }}>
            <ModalHeader>Räume für {props.map.label} bearbeiten</ModalHeader>
            <ModalBody>
                <Block display="grid" gridTemplateColumns="auto 1fr" gridColumnGap="32px">
                    <div style={{ width: '600px' }}>
                        <RemoteImageWithMarkers
                            imageId={props.map.image}
                            markers={roomsData?.data.map((it) => ({
                                id: it.id,
                                active: it.id === activeRoom?.id,
                                relativeX: it.coordX,
                                relativeY: it.coordY,
                                label: it.name,
                            }))}
                        />
                    </div>
                    <div>
                        <TableBuilder
                            data={roomsData?.data}
                            isLoading={!roomsData?.data}
                            emptyMessage="Keine Räume"
                            size="compact"
                        >
                            <TableBuilderColumn header="Name">{(row: RoomMessage) => row.name}</TableBuilderColumn>
                            <TableBuilderColumn header="Position" numeric>
                                {(row: RoomMessage) => (
                                    <code>
                                        {row.coordX}, {row.coordY}
                                    </code>
                                )}
                            </TableBuilderColumn>
                            <TableBuilderColumn header="" numeric>
                                {(row: RoomMessage) => (
                                    <div>
                                        <Button size={SIZE.mini} onClick={() => setActiveRoom(row)}>
                                            Bearbeiten
                                        </Button>{' '}
                                        <Button
                                            size={SIZE.mini}
                                            kind={KIND.secondary}
                                            onClick={() => handleDelete(row.id)}
                                        >
                                            Löschen
                                        </Button>
                                    </div>
                                )}
                            </TableBuilderColumn>
                        </TableBuilder>
                        <Block marginTop="scale400">
                            <Button
                                size={SIZE.compact}
                                kind={KIND.secondary}
                                onClick={() => {
                                    setActiveRoom({
                                        id: 0,
                                        name: '',
                                        coordX: 0.5,
                                        coordY: 0.5,
                                        map: props.map.id,
                                    });
                                }}
                            >
                                Hinzufügen
                            </Button>
                        </Block>
                    </div>
                </Block>
            </ModalBody>
            <ModalFooter>
                {activeRoom ? (
                    <EditRoomModal
                        room={activeRoom}
                        onClose={() => setActiveRoom(undefined)}
                        onSubmit={handleUpdateRoom}
                    />
                ) : null}
            </ModalFooter>
        </Modal>
    );
};
