import { toaster } from 'baseui/toast';
import { DateTime } from 'luxon';
import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { PageContent } from '../../../common/components/PageContent';
import { getGetFeedIdGetKey, postFeedCreate } from '../../../generated-apis/nZWAppAPI';
import { FeedEditForm, FeedMessageWithType } from '../components/FeedEditForm';

export const FeedCreatePage = () => {
    const { congressId } = useParams();
    const navigate = useNavigate();
    const { mutate } = useSWRConfig();

    const createFeed: SubmitHandler<FeedMessageWithType> = async (values) => {
        try {
            const response = await postFeedCreate({
                ...values,
                // the server does not accept non-Z timezones and also considers Z as the local zone
                // therefore we disregard the timezone from the iso string and replace it with Z
                date: values.date.replace(/\+.+/, 'Z'),
            });

            toaster.positive('Erfolgreich erstellt');
            await mutate(getGetFeedIdGetKey(response.data.id), response);
            navigate(`/congresses/${congressId}/feeds/${response.data.id}`);
        } catch (error) {
            toaster.negative('Speichern fehlgeschlagen');
        }
    };

    const defaultValues = {
        id: 0,
        congress: Number(congressId),
        text: '',
        date: DateTime.now().toISO(),
        type: '',
    };

    return (
        <PageContent title="Neu anlegen">
            <FeedEditForm onSubmit={createFeed} defaultValues={defaultValues} />
        </PageContent>
    );
};
