import { Button, SIZE } from 'baseui/button';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { PageContent } from '../../../common/components/PageContent';
import { EventsList } from '../components/EventsList';

export const EventListPage = () => {
    const { congressId } = useParams();

    return (
        <PageContent
            title="Veranstaltungen"
            actionElement={
                <Button size={SIZE.compact} $as={Link} to={`/congresses/${congressId}/events/create`}>
                    Neu anlegen
                </Button>
            }
        >
            <EventsList />
        </PageContent>
    );
};
