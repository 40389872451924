import { Button, SIZE } from 'baseui/button';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { PageContent } from '../../../common/components/PageContent';
import { FeedList } from '../components/FeedList';

export const FeedListPage = () => {
    const { congressId } = useParams();

    return (
        <PageContent
            title="Aktuelles"
            actionElement={
                <Button size={SIZE.compact} $as={RouterLink} to={`/congresses/${congressId}/feeds/create`}>
                    Neu anlegen
                </Button>
            }
        >
            <FeedList />
        </PageContent>
    );
};
