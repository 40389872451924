import { AxiosResponse } from 'axios';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Button, KIND, SIZE } from 'baseui/button';
import { Card, StyledBody } from 'baseui/card';
import { FileUploader } from 'baseui/file-uploader';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { StyledLink } from 'baseui/link';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import { toaster } from 'baseui/toast';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { PageContent } from '../../../common/components/PageContent';
import {
    deleteFileIdDelete,
    getGetCongressCongressidFileListKey,
    postFileUpload,
    useGetCongressCongressidFileList,
} from '../../../generated-apis/nZWAppAPI';
import { FileTypeMessage } from '../../../generated-apis/nZWAppAPI.schemas';

interface AddNewFileProps {
    congressId: number;
}

interface FileFormState {
    name: string;
    extension: string;
    content?: File;
}

const AddNewFile = ({ congressId }: AddNewFileProps) => {
    const [, theme] = useStyletron();
    const [isOpen, setIsOpen] = useState(false);
    const { mutate } = useSWRConfig();

    const { control, handleSubmit, setValue, watch, formState } = useForm<FileFormState>({
        defaultValues: {
            name: '',
            extension: '',
            content: undefined,
        },
    });

    useEffect(() => {
        if (isOpen) {
            setValue('name', '');
            setValue('extension', '');
            setValue('content', undefined);
        }
    }, [isOpen, setValue]);

    const handleFileDrop = (accepted: File[], rejected: File[]) => {
        if (rejected.length > 0) {
            toaster.negative(`Die Datei "${rejected[0].name}" kann nicht verwendet werden.`);
            return;
        }

        const filenameParts = accepted[0].name.split('.');
        const extension = filenameParts.pop();

        setValue('name', filenameParts.join('.'));
        setValue('extension', extension || '');
        setValue('content', accepted[0]);
    };

    const addNewFile: SubmitHandler<FileFormState> = async (values) => {
        try {
            const response = await postFileUpload({
                congress: congressId,
                filename: values.name,
                fileext: values.extension,
                file: values.content,
            });

            await mutate(
                getGetCongressCongressidFileListKey(congressId),
                (current: AxiosResponse<FileTypeMessage[]>) => {
                    current.data.push(response.data);

                    return current;
                }
            );

            toaster.positive('Datei erfolgreich hochgeladen');
            setIsOpen(false);
        } catch (ex) {
            console.warn(ex);
            toaster.negative('Datei konnte nicht hochgeladen werden');
        }
    };

    const selectedFile = watch('content');

    return (
        <>
            <Button size={SIZE.compact} onClick={() => setIsOpen(true)}>
                Hinzufügen
            </Button>
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <ModalHeader>Datei hochladen</ModalHeader>
                <form onSubmit={handleSubmit(addNewFile)}>
                    <ModalBody>
                        <Block marginBottom="scale600">
                            {selectedFile ? (
                                <Card>
                                    <StyledBody $style={{ color: theme.colors.contentSecondary }}>
                                        {selectedFile.name}
                                    </StyledBody>
                                </Card>
                            ) : (
                                <FileUploader multiple={false} onDrop={handleFileDrop} />
                            )}
                        </Block>
                        <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                                <div>
                                    <FormControl label="Dateiname">
                                        <Input {...field} endEnhancer={watch('extension')} />
                                    </FormControl>
                                </div>
                            )}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <ModalButton kind={KIND.tertiary} type="button" onClick={() => setIsOpen(false)}>
                            Abbrechen
                        </ModalButton>
                        <ModalButton type="submit" isLoading={formState.isSubmitting}>
                            Speichern
                        </ModalButton>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    );
};

export const CongressFilesPage = () => {
    const { congressId } = useParams();
    const { data, mutate } = useGetCongressCongressidFileList(Number(congressId));

    const handleDeleteFile = async (fileId: number) => {
        try {
            await deleteFileIdDelete(fileId);
            await mutate((current: AxiosResponse<FileTypeMessage[]> | undefined) => {
                if (current) {
                    current.data = current.data.filter((it) => it.id !== fileId);
                }

                return current;
            });
            toaster.positive('Erfolgereich gelöscht');
        } catch (ex) {
            toaster.negative('Löschen fehlgeschlagen');
        }
    };

    return (
        <PageContent title="Dateien" actionElement={<AddNewFile congressId={Number(congressId)} />}>
            <TableBuilder data={data?.data} isLoading={!data} emptyMessage="Keine Dateien">
                <TableBuilderColumn header="Name">{(row: FileTypeMessage) => row.filename}</TableBuilderColumn>
                <TableBuilderColumn header="Path">
                    {(row: FileTypeMessage) => (
                        <StyledLink
                            href={`${process.env.REACT_APP_API_BASE_URL}/file/${row.id}/download`}
                            target="_blank"
                        >
                            {row.path}
                        </StyledLink>
                    )}
                </TableBuilderColumn>
                <TableBuilderColumn header="" numeric>
                    {(row: FileTypeMessage) => (
                        <>
                            <Button size={SIZE.mini} kind={KIND.secondary} onClick={() => handleDeleteFile(row.id)}>
                                Löschen
                            </Button>
                        </>
                    )}
                </TableBuilderColumn>
            </TableBuilder>
        </PageContent>
    );
};
