import { useStyletron } from 'baseui';
import { Spinner } from 'baseui/spinner';

export const CenteredSpinner = () => {
    const [css] = useStyletron();

    return (
        <div className={css({ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -100%)' })}>
            <Spinner />
        </div>
    );
};
