import { Block } from 'baseui/block';
import { FileUploader } from 'baseui/file-uploader';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { toaster } from 'baseui/toast';
import React, { useContext } from 'react';
import { useController } from 'react-hook-form';
import { postFileUpload, useGetFileIdGet } from '../../../generated-apis/nZWAppAPI';
import { LanguageContext } from '../context/LanguageContextProvider';
import { LanguageSelect } from './LanguageSelect';

interface FormLanguageAwareFileUploadProps {
    name: string;
    label?: string;
}

export const FormLanguageAwareFileUpload = ({ name, label }: FormLanguageAwareFileUploadProps) => {
    const {
        language: { languageId },
    } = useContext(LanguageContext);
    const controller = useController({ name });

    const abstractFileId = controller.field.value ? controller.field.value[languageId] : undefined;

    const { data: currentFileData } = useGetFileIdGet(abstractFileId, {
        swr: {
            enabled: abstractFileId !== undefined,
        },
    });

    const handleFileDrop = async (accepted: File[], rejected: File[]) => {
        if (rejected.length > 0) {
            toaster.negative(`Die Datei "${rejected[0].name}" kann nicht verwendet werden.`);
            return;
        }

        const filenameParts = accepted[0].name.split('.');
        const extension = filenameParts.pop();

        try {
            const response = await postFileUpload({
                congress: undefined,
                filename: filenameParts.join('.'),
                fileext: extension,
                file: accepted[0],
            });

            console.log('updating field with uploaded file', response.data);
            controller.field.onChange({
                ...controller.field.value,
                [languageId]: response.data.id,
            });
        } catch (ex) {
            toaster.negative('Datei konnte nicht hochgeladen werden.');
        }
    };

    let selectedFilename = 'Keine Datei ausgewählt';

    if (abstractFileId) {
        selectedFilename = 'Loading...';

        if (currentFileData?.data) {
            selectedFilename = `${currentFileData?.data?.filename}.${currentFileData?.data?.fileext}`;
        }
    }

    return (
        <div>
            <FormControl label={label}>
                <Block>
                    <Block
                        display="grid"
                        gridTemplateColumns="auto 1fr"
                        gridColumnGap="scale400"
                        marginBottom="scale400"
                    >
                        <div>
                            <LanguageSelect />
                        </div>
                        <div>
                            <Block marginBottom="scale400">
                                <Input value={selectedFilename} readOnly disabled />
                            </Block>
                            <FileUploader onDrop={handleFileDrop} />
                        </div>
                    </Block>
                </Block>
            </FormControl>
        </div>
    );
};
