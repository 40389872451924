import { Block } from 'baseui/block';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { ExhibitorMessage } from '../../../generated-apis/nZWAppAPI.schemas';
import { ImagePreviewAndFileUpload } from '../../locations/components/ImagePreviewAndFileUpload';
import { FormMapSelect } from './FormMapSelect';
import { FormMapWithExhibitorLocation } from './FormMapWithExhibitorLocation';

interface ExhibitorEditFormProps {
    control: Control<ExhibitorMessage>;
}

export const ExhibitorEditForm = ({ control }: ExhibitorEditFormProps) => {
    const { watch } = useFormContext();

    return (
        <Block display="grid" gridTemplateColumns="1fr 480px" gridColumnGap="scale800">
            <div>
                <Block display="grid" gridTemplateColumns="6fr 2fr" gridColumnGap="scale400">
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                            <div>
                                <FormControl label="Name">
                                    <Input {...field} />
                                </FormControl>
                            </div>
                        )}
                    />

                    <Controller
                        name="boothnr"
                        control={control}
                        render={({ field }) => (
                            <div>
                                <FormControl label="Standnummer">
                                    <Input {...field} />
                                </FormControl>
                            </div>
                        )}
                    />
                </Block>

                <Controller
                    name="website"
                    control={control}
                    render={({ field }) => (
                        <div>
                            <FormControl label="Website">
                                <Input {...field} />
                            </FormControl>
                        </div>
                    )}
                />

                <Controller
                    name="logo"
                    control={control}
                    render={({ field }) => (
                        <div>
                            <FormControl label="Bild">
                                <Block>
                                    <ImagePreviewAndFileUpload
                                        onUpload={(uploadedImageId) =>
                                            field.onChange({ target: { value: uploadedImageId } })
                                        }
                                        imageId={field.value}
                                    />
                                </Block>
                            </FormControl>
                        </div>
                    )}
                />
            </div>
            <div>
                <FormControl label="Standort">
                    <Block display="flex" flexDirection="column" gridGap="scale400">
                        <FormMapSelect name="map" congressId={watch('congress')} />
                        <FormMapWithExhibitorLocation />
                    </Block>
                </FormControl>
                <Block display="grid" gridTemplateColumns="1fr 1fr" gridColumnGap="scale400">
                    <Controller
                        name="coordX"
                        control={control}
                        render={({ field }) => (
                            <div>
                                <FormControl label="Position X">
                                    <Input {...field} />
                                </FormControl>
                            </div>
                        )}
                    />
                    <Controller
                        name="coordY"
                        control={control}
                        render={({ field }) => (
                            <div>
                                <FormControl label="Position Y">
                                    <Input {...field} />
                                </FormControl>
                            </div>
                        )}
                    />
                </Block>
            </div>
        </Block>
    );
};
