import { Modal, ModalBody, ModalHeader, ModalProps } from 'baseui/modal';
import { toaster } from 'baseui/toast';
import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { getGetReferentIdGetKey, postReferentCreate } from '../../../generated-apis/nZWAppAPI';
import { ReferentMessage } from '../../../generated-apis/nZWAppAPI.schemas';
import { SpeakerEditForm } from './SpeakerEditForm';

interface SpeakerCreateModalProps extends Pick<ModalProps, 'isOpen' | 'onClose'> {}

export const SpeakerCreateModal = ({ isOpen, onClose }: SpeakerCreateModalProps) => {
    const navigate = useNavigate();
    const { mutate } = useSWRConfig();
    const onSubmit: SubmitHandler<ReferentMessage> = async (data) => {
        try {
            const response = await postReferentCreate(data);
            await mutate(getGetReferentIdGetKey(response.data.id), response);

            toaster.positive('Erfolgreich gespeichert');
            navigate(`/speakers/${response.data.id}`);
        } catch (error) {
            toaster.negative('Speichern fehlgeschlagen');
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} overrides={{ Dialog: { style: { width: '50vw' } } }}>
            <ModalHeader>Referent hinzufügen</ModalHeader>
            <ModalBody>
                <SpeakerEditForm
                    onSubmit={onSubmit}
                    defaultValues={{
                        id: 0,
                        title: '',
                        firstname: '',
                        lastname: '',
                        city: '',
                        country: '',
                    }}
                />
            </ModalBody>
        </Modal>
    );
};
