import { Select } from 'baseui/select';
import * as React from 'react';
import { useController } from 'react-hook-form';
import { useGetCongressIdGet, useGetLocationLocationidMapList } from '../../../generated-apis/nZWAppAPI';

interface FormMapSelectProps {
    congressId: number;
    name: string;
}

export const FormMapSelect = ({ congressId, name }: FormMapSelectProps) => {
    const { field } = useController({ name });
    const { data: congressData } = useGetCongressIdGet(congressId);
    const { data: mapsData } = useGetLocationLocationidMapList(congressData?.data.location || 0, {
        swr: {
            enabled: !!congressData?.data,
        },
    });

    const options =
        mapsData?.data.map((it) => ({
            id: it.id,
            label: it.label,
        })) ?? [];

    const selected = options.filter((it) => it.id === field.value);

    return (
        <Select
            options={options}
            value={selected}
            isLoading={!mapsData?.data}
            clearable={false}
            onChange={(params) => {
                field.onChange({
                    target: {
                        value: params.value[0].id,
                    },
                });
            }}
        />
    );
};
