import { Button, SIZE } from 'baseui/button';
import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { PageContent } from '../../../common/components/PageContent';
import { ExhibitorsList } from '../components/ExhibitorsList';

export const ExhibitorListPage = () => {
    const { congressId } = useParams();

    return (
        <PageContent
            title="Aussteller"
            actionElement={
                <Button size={SIZE.compact} $as={RouterLink} to={`/congresses/${congressId}/exhibitors/create`}>
                    Neu anlegen
                </Button>
            }
        >
            <ExhibitorsList />
        </PageContent>
    );
};
