import { Button, KIND, SIZE } from 'baseui/button';
import { Select } from 'baseui/select';
import { Spinner } from 'baseui/spinner';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import { toaster } from 'baseui/toast';
import { useEffect, useState } from 'react';
import {
    postEventEventidReferentUpdate,
    useGetEventEventidReferentList,
    useGetReferentList,
} from '../../../generated-apis/nZWAppAPI';
import { ReferentEventMessage } from '../../../generated-apis/nZWAppAPI.schemas';

interface EventSpeakerEditFormProps {
    eventId: number;
}

export const EventSpeakerEditForm = ({ eventId }: EventSpeakerEditFormProps) => {
    const { data: eventSpeakersData } = useGetEventEventidReferentList(eventId);
    const { data: speakersData } = useGetReferentList();

    const [selectedSpeakers, setSelectedSpeakers] = useState<ReferentEventMessage[]>([]);

    useEffect(() => {
        if (eventSpeakersData && selectedSpeakers.length === 0) {
            setSelectedSpeakers(eventSpeakersData.data);
        }
    }, [eventSpeakersData, selectedSpeakers.length]);

    const handleSubmit = async () => {
        try {
            await postEventEventidReferentUpdate(eventId, { referents: selectedSpeakers });
            toaster.positive('Erfolgreich gespeichert');
        } catch (ex) {
            toaster.negative('Speichern fehlgeschlagen');
        }
    };

    if (!eventSpeakersData || !speakersData) {
        return <Spinner />;
    }

    const speakersOptions = speakersData.data.map((it) => ({
        label: [it.title, it.firstname, it.lastname].join(' '),
        id: it.id,
    }));

    const eventSpeakerTypeOptions = [
        {
            label: 'Referent',
            id: 'referent',
        },
        {
            label: 'Chair',
            id: 'chair',
        },
    ];

    return (
        <>
            <TableBuilder emptyMessage="Keine Referenten" data={selectedSpeakers}>
                <TableBuilderColumn header="Referent">
                    {(row: ReferentEventMessage, rowIndex) => {
                        const selectedSpeaker = speakersOptions.find((it) => it.id === row.referent) || [];

                        return (
                            <Select
                                size="compact"
                                clearable={false}
                                options={speakersOptions}
                                value={[selectedSpeaker]}
                                onChange={({ value }) => {
                                    const updatedSpeakers = selectedSpeakers.map((it, idx) => {
                                        if (idx === rowIndex) {
                                            return {
                                                event: it.event,
                                                referent: value[0].id as number,
                                                type: it.type,
                                            };
                                        }

                                        return it;
                                    });

                                    setSelectedSpeakers(updatedSpeakers);
                                }}
                            />
                        );
                    }}
                </TableBuilderColumn>
                <TableBuilderColumn header="Typ">
                    {(row: ReferentEventMessage, rowIndex) => {
                        const selectedType = eventSpeakerTypeOptions.find((it) => it.id === row.type) || [];

                        return (
                            <Select
                                size="compact"
                                clearable={false}
                                options={eventSpeakerTypeOptions}
                                value={[selectedType]}
                                onChange={({ value }) => {
                                    const updatedSpeakers = selectedSpeakers.map((it, idx) => {
                                        if (idx === rowIndex) {
                                            return {
                                                event: it.event,
                                                referent: it.referent,
                                                type: value[0].id as string,
                                            };
                                        }

                                        return it;
                                    });

                                    setSelectedSpeakers(updatedSpeakers);
                                }}
                            />
                        );
                    }}
                </TableBuilderColumn>
                <TableBuilderColumn header="" numeric>
                    {(row: ReferentEventMessage, rowIndex) => (
                        <Button
                            size="compact"
                            kind="secondary"
                            onClick={() => {
                                setSelectedSpeakers(selectedSpeakers.filter((it, index) => index !== rowIndex));
                            }}
                        >
                            Löschen
                        </Button>
                    )}
                </TableBuilderColumn>
            </TableBuilder>
            <Button
                size={SIZE.compact}
                kind={KIND.secondary}
                onClick={() => {
                    setSelectedSpeakers([...selectedSpeakers, { event: eventId, referent: 0, type: 'referent' }]);
                }}
            >
                Hinzufügen
            </Button>
            <br />
            <br />

            <Button onClick={handleSubmit}>Speichern</Button>
        </>
    );
};
