import { Button, SIZE } from 'baseui/button';
import { StyledLink } from 'baseui/link';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import React from 'react';
import { Link, Link as RouterLink, useParams } from 'react-router-dom';
import { PageContent } from '../../../common/components/PageContent';
import { useGetCongressCongressidAdList } from '../../../generated-apis/nZWAppAPI';
import { AdMessage } from '../../../generated-apis/nZWAppAPI.schemas';

export const AdListPage = () => {
    const { congressId } = useParams();
    const { data: adsData } = useGetCongressCongressidAdList(Number(congressId));

    return (
        <PageContent
            title="Werbung"
            actionElement={
                <Button size={SIZE.compact} $as={RouterLink} to={`/congresses/${congressId}/ads/create`}>
                    Neu anlegen
                </Button>
            }
        >
            <TableBuilder isLoading={!adsData} data={adsData?.data} emptyMessage="Keine Werbung">
                <TableBuilderColumn header="Name">
                    {(row: AdMessage) => (
                        <StyledLink $as={Link} to={`/congresses/${congressId}/ads/${row.id}`}>
                            {row.name}
                        </StyledLink>
                    )}
                </TableBuilderColumn>
                <TableBuilderColumn header="Ziel-URL">{(row: AdMessage) => row.targetUrl}</TableBuilderColumn>
            </TableBuilder>
        </PageContent>
    );
};
