import React, { ReactNode, useState } from 'react';

interface Language {
    languageId: number;
    name: string;
}

interface ILanguageContext {
    language: Language;
    availableLanguages: Language[];
    setLanguage: (language: Language) => void;
}

export const LanguageContext = React.createContext<ILanguageContext>({
    language: {
        languageId: 1,
        name: 'Deutsch',
    },
    availableLanguages: [],
    setLanguage: () => {
        throw new Error('LanguageContext not initialized');
    },
});

export const LanguageContextProvider = (props: { children: ReactNode }) => {
    const availableLanguages = [
        { name: '🇩🇪', languageId: 1 },
        { name: '🇬🇧', languageId: 2 },
    ];

    const [language, setLanguage] = useState<Language>({
        languageId: 1,
        name: '🇩🇪',
    });

    return <LanguageContext.Provider {...props} value={{ language, availableLanguages, setLanguage }} />;
};
