import { createContext, ReactNode, useEffect, useState } from 'react';
import { parseJwt } from '../util/parseJwt';

interface GoogleUserInfo {
    email?: string;
    email_verified?: boolean;
    name?: string;
    picture?: string;
    exp?: number;
}

interface IUserAuthenticationContext {
    isLoggedIn: boolean;
    userInfo?: GoogleUserInfo;
    setAccessToken: (accessToken: string | null) => void;
}

export const UserAuthenticationContext = createContext<IUserAuthenticationContext>({
    isLoggedIn: false,
    userInfo: undefined,
    setAccessToken: () => {
        throw Error('setAccessToken cannot be used outside of the UserAuthenticationContext');
    },
});

interface UserAuthenticationContextProviderProps {
    children: ReactNode;
}

export const UserAuthenticationContextProvider = (props: UserAuthenticationContextProviderProps) => {
    const [accessToken, setAccessToken] = useState<string | null>(localStorage.getItem('accessToken'));
    const userInfo: GoogleUserInfo = {};

    if (accessToken) {
        const decoded = parseJwt<GoogleUserInfo>(accessToken);
        const expirationDate = new Date(decoded.exp!! * 1_000);

        if (expirationDate <= new Date()) {
            window.location.href = '/logout';
        }

        userInfo.name = decoded.name;
        userInfo.email = decoded.email;
        userInfo.picture = decoded.picture;
    }

    useEffect(() => {
        if (accessToken) {
            localStorage.setItem('accessToken', accessToken);
        } else {
            localStorage.removeItem('accessToken');
        }
    }, [accessToken]);

    const context: IUserAuthenticationContext = {
        isLoggedIn: !!accessToken,
        userInfo: userInfo,
        setAccessToken: (newAccessToken) => setAccessToken(newAccessToken),
    };

    return <UserAuthenticationContext.Provider {...props} value={context} />;
};
