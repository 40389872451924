import React from 'react';
import { useFormContext } from 'react-hook-form';
import { RemoteImageWithMarkers } from '../../../common/components/RemoteImageWithMarkers';
import { useGetMapIdGet } from '../../../generated-apis/nZWAppAPI';

export const FormMapWithExhibitorLocation = () => {
    const { watch } = useFormContext();
    const [coordX, coordY, map] = watch(['coordX', 'coordY', 'map']);
    const { data: mapData } = useGetMapIdGet(map);

    if (!mapData) {
        return null;
    }

    return (
        <RemoteImageWithMarkers
            imageId={mapData.data.image}
            markers={[
                {
                    label: 'Standort',
                    relativeX: coordX,
                    relativeY: coordY,
                    active: false,
                    id: mapData.data.id,
                },
            ]}
        />
    );
};
