import { Block } from 'baseui/block';
import { Button, SIZE } from 'baseui/button';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import { toaster } from 'baseui/toast';
import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { postMapCreate, postMapIdUpdate, useGetLocationLocationidMapList } from '../../../generated-apis/nZWAppAPI';
import { MapTypeMessage } from '../../../generated-apis/nZWAppAPI.schemas';
import { MapDetailEditModal } from './MapDetailEditModal';
import { MapRoomsEditModal } from './MapRoomsEditModal';

export const MapsEditForm = () => {
    const { locationId: locationIdParam } = useParams();
    const locationId = Number(locationIdParam);

    const { data: mapsData, mutate } = useGetLocationLocationidMapList(locationId);
    const [activePreview, setActivePreview] = useState<MapTypeMessage | undefined>(undefined);
    const [editRoomsMap, setEditRoomsMap] = useState<MapTypeMessage | undefined>(undefined);

    function closeEditModal() {
        setActivePreview(undefined);
    }

    function closeEditRoomsModal() {
        setEditRoomsMap(undefined);
    }

    const onSubmit: SubmitHandler<MapTypeMessage> = async (values: MapTypeMessage) => {
        try {
            if (values.id > 0) {
                await postMapIdUpdate(values.id, values);
            } else {
                await postMapCreate(values);
            }

            await mutate();
            toaster.positive('Erfolgreich gespeichert');
            setActivePreview(undefined);
        } catch (error) {
            toaster.negative('Speichern fehlgeschlagen');
        }
    };

    return (
        <div>
            <TableBuilder data={mapsData?.data} isLoading={!mapsData} emptyMessage="Keine Karten">
                <TableBuilderColumn header="Name">{(row: MapTypeMessage) => row.label}</TableBuilderColumn>
                <TableBuilderColumn header="" numeric>
                    {(row: MapTypeMessage) => (
                        <>
                            <Button kind="secondary" size="mini" onClick={() => setActivePreview(row)}>
                                Bearbeiten
                            </Button>
                            &nbsp; &nbsp;
                            <Button kind="secondary" size="mini" onClick={() => setEditRoomsMap(row)}>
                                Räume bearbeiten
                            </Button>
                        </>
                    )}
                </TableBuilderColumn>
            </TableBuilder>
            <Block marginTop="16px" display="flex" justifyContent="end" marginRight="scale600">
                <Button
                    size={SIZE.compact}
                    onClick={() => {
                        setActivePreview({ id: 0, location: locationId, label: '', image: 0 });
                    }}
                >
                    Hinzufügen
                </Button>
            </Block>
            {activePreview ? (
                <MapDetailEditModal
                    map={activePreview}
                    locationId={locationId}
                    onClose={closeEditModal}
                    onSubmit={onSubmit}
                />
            ) : null}
            {editRoomsMap ? <MapRoomsEditModal map={editRoomsMap} onClose={closeEditRoomsModal} /> : null}
        </div>
    );
};
