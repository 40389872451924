import { Select } from 'baseui/select';
import React, { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContextProvider';

export const LanguageSelect = () => {
    const { language, availableLanguages, setLanguage } = useContext(LanguageContext);

    return (
        <Select
            options={availableLanguages.map((it) => ({ label: it.name, id: it.languageId }))}
            value={[{ label: language.name, id: language.languageId }]}
            clearable={false}
            searchable={false}
            overrides={{ Root: { style: { width: '80px' } } }}
            onChange={({ value }) =>
                setLanguage({
                    name: value[0].label as string,
                    languageId: value[0].id as number,
                })
            }
        />
    );
};
