import { StyledLink } from 'baseui/link';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import { DateTime } from 'luxon';
// @ts-ignore
import ReactHtmlParser from 'react-html-parser';
import { Link, useParams } from 'react-router-dom';
import { useGetCongressCongressidFeedList } from '../../../generated-apis/nZWAppAPI';
import { FeedMessage } from '../../../generated-apis/nZWAppAPI.schemas';

export const FeedList = () => {
    const { congressId } = useParams();
    const { data: feedData } = useGetCongressCongressidFeedList(Number(congressId));

    return (
        <TableBuilder isLoading={!feedData} emptyMessage="Keine Nachrichten" data={feedData?.data}>
            <TableBuilderColumn header="Datum">
                {(row: FeedMessage) => DateTime.fromISO(row.date).toLocaleString(DateTime.DATETIME_MED)}
            </TableBuilderColumn>
            <TableBuilderColumn header="Typ">{(row: FeedMessage) => row.type as string}</TableBuilderColumn>
            <TableBuilderColumn header="Text">
                {(row: FeedMessage) => (
                    <StyledLink $as={Link} to={`/congresses/${congressId}/feeds/${row.id}`}>
                        {ReactHtmlParser(row.text)}
                    </StyledLink>
                )}
            </TableBuilderColumn>
        </TableBuilder>
    );
};
