import { Navigate, Outlet, useLocation, useNavigation } from 'react-router-dom';
import { CenteredSpinner } from '../components/CenteredSpinner';
import { useIsLoggedIn } from '../hooks/useIsLoggedIn';

export const RootPage = () => {
    const navigation = useNavigation();
    const { pathname } = useLocation();
    const isLoggedIn = useIsLoggedIn();

    if (!isLoggedIn && pathname !== '/login') {
        return <Navigate to="/login" />;
    }

    if (pathname === '/') {
        return <Navigate to="/congresses" />;
    }

    return (
        <>
            {navigation.state === 'loading' && <CenteredSpinner />}
            <Outlet />
        </>
    );
};
